import ContentTitle from '../../modules/atoms/contentTile';
import { useState } from 'react';

const data = [
  {
    id: 1,
    title: '1. Can EF Polymer be used for houseplants and home gardens?',
    ans: 'Yes, it can be used for any plant or crop that needs soil and water to grow.',
  },
  {
    id: 2,
    title: '2. What is the suitable dosage for my crop/ plant?',
    ans: 'We understand that every crop/ plant has unique needs. Therefore, please connect with our helpline to get the correct answer.',
  },
  {
    id: 3,
    title: '3. Is it certified as an organic product?',
    ans: 'Yes, EF Polymer is a certified product that can be used for organic farming along with non-organic farming.',
  },
  {
    id: 4,
    title: '4. Is it a biofertilizer?',
    ans: 'No! EF Polymer naturally contains micronutrients that nourish crops, but it can not be a substitute for any fertilizer.',
  },
  {
    id: 5,
    title: '5. Can Fasal Amrit be used for any other industry than agriculture?',
    ans: 'Fasal Amrit is created particularly for agriculture but the polymer can be used for other products. Please visit the technology page to know more!',
  },
];

export const FAQ = () => {
  const [selected, setSelected] = useState(0);

  return (
    <section className='blade-pt-sm xl:blade-pt pb-12 lg:blade-pb bg-brownMedium'>
      <div className='container'>
        <ContentTitle center className='mx-auto max-w-2xl' title='Frequently Asked Questions' />

        <div className='grid w-full gap-y-6 flex-col mt-8 lg:mt-16'>
          {data.map((item, index) => (
            <div className='w-full rounded-2xl border border-grayLight' key={index}>
              <div
                onClick={() => {
                  item.id === selected ? setSelected(0) : setSelected(item.id);
                }}
                className={`flex w-full bg-transparent rounded-t-lg py-4 px-4 sm:px-6 md:px-8 transition-all duration-300 
                items-center justify-between cursor-pointer gap-x-3 ${
                  selected === item.id ? ' cursor-auto' : ''
                }`}
              >
                <h3 className='font-normal text-lg md:text-xl lg:text-2xl text-left max-w-md sm:max-w-xl md:max-w-4xl transition-all duration-500'>
                  {item.title}
                </h3>

                <span
                  tabIndex={0}
                  onKeyDown={() => {
                    item.id === selected ? setSelected(0) : setSelected(item.id);
                  }}
                  className='bg-greenDark outline-green outline-offset-4 p-2 lg:p-4 rounded-full stroke-white'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='inhert'
                    className={`w-6 h-6 transition-all duration-500 ${
                      selected === item.id ? 'rotate-90' : 'rotate-0'
                    }`}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M8.25 4.5l7.5 7.5-7.5 7.5'
                    />
                  </svg>
                </span>
              </div>
              {/* open part */}
              <div
                className={`bg-transparent grid transition-all duration-500 grid-rows-[0fr] ${
                  selected === item.id ? 'grid-rows-[1fr] py-4 px-4 sm:px-6 md:px-8 delay-300' : ''
                }`}
              >
                <div className='min-h-0 overflow-hidden'>
                  <p className='font-normal text-black text-base md:text-lg lg:text-xl opacity-70'>
                    {item.ans}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
