import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export default function ContentTile({
  label,
  title,
  p1,
  p2,
  p3,
  p4,
  className,
  animTrigger,
  center,
  themeDark,
}) {
  return (
    <div
      className={`${className}  ${
        center ? "text-center mx-auto" : ""
      } grid gap-y-3 md:gap-4 animate-box-fade-in`}
    >
      {themeDark ? (
        <>
          {label ? (
            <p
              id="text"
              className="text-white uppercase font-normal font-object tracking-wider text-[14px] md:text-lg xl:text-xl"
            >
              {label}
            </p>
          ) : (
            ""
          )}
          {title ? (
            <h2
              id="text"
              className="text-yellow font-bold tracking-tight leading-tight"
            >
              {title}
            </h2>
          ) : (
            ""
          )}
          <div className={`${center ? "" : "pr-10"} grid gap-3`}>
            {p1 ? (
              <p
                id="text"
                className="opacity-60 font-normal text-white animate-text-fade-in md:text-lg text-[14px]"
              >
                {p1}
              </p>
            ) : (
              ""
            )}
            {p2 ? (
              <p
                id="text"
                className="text-white font-normal md:text-lg text-[14px]"
              >
                {p2}
              </p>
            ) : (
              ""
            )}
            {p3 ? (
              <p
                id="text"
                className="text-white font-normal md:text-lg text-[14px]"
              >
                {p3}
              </p>
            ) : (
              ""
            )}
            {p4 ? (
              <p
                id="text"
                className="text-yellow md:px-10 font-normal md:text-lg text-[14px]"
                dangerouslySetInnerHTML={{ __html: p4 }}
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <>
          {label ? (
            <p
              id="text"
              className="uppercase  text-green  font-normal tracking-wider text-[14px] md:text-lg xl:text-xl"
            >
              {label}
            </p>
          ) : (
            ""
          )}
          {title ? (
            <h2
              id="text"
              className="text-greenDark font-bold leading-tight tracking-tight"
            >
              {title}
            </h2>
          ) : (
            ""
          )}
          <div className={`${center ? "" : "pr-10"} grid gap-3`}>
            {p1 ? (
              <p
                id="text"
                className="opacity-60 font-normal md:text-lg text-[14px] leading-normal"
              >
                {p1}
              </p>
            ) : (
              ""
            )}

            {p2 ? (
              <p
                id="text"
                className="text-black font-plainNormal md:text-lg text-sm"
                dangerouslySetInnerHTML={{ __html: p2 }}
              />
            ) : (
              ""
            )}

            {p3 ? (
              <p id="text" className="md:text-lg font-normal text-[14px]">
                {p3}
              </p>
            ) : (
              ""
            )}

            {p4 ? (
              <p
                id="text"
                className="text-greenDark md:text-lg font-normal text-[14px] md:px-10"
                dangerouslySetInnerHTML={{ __html: p4 }}
              />
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
}
