import ContentTile from '../../modules/atoms/contentTile';
import Ankit from '../../assets/about/team/ankit.webp';
import Hiroshi from '../../assets/about/team/hiroshi.webp';
import Kunihiro from '../../assets/about/team/kunihiro.webp';
import Puran from '../../assets/about/team/puran.webp';
import Narayan from '../../assets/about/team/narayan.webp';
// import linkedInIcon from '../../assets/about/team/linkedin.svg';

const Card = ({ img, name, designation, link }) => {
  return (
    <div className='flex flex-col items-center '>
      <a
        rel='noreferrer'
        href={link}
        target='_blank'
        aria-label={name}
        className='w-[60%] rounded-full !rounded-bl-2xl outline-green lg:w-[65%] 2xl:w-[75%] h-full relative group'
      >
        <img className='w-full h-full' src={img} alt={name} />
        <span className='mt-1 absolute bottom-5 left-5 group-hover:bg-[#0a66c2] group-focus-visible:bg-[#0a66c2] transition-colors rounded-md p-1'>
          <svg
            className=' fill-white h-6 stroke-white '
            strokeWidth='0'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M22.0367422,22 L17.8848745,22 L17.8848745,15.5036305 C17.8848745,13.9543347 17.85863,11.9615082 15.7275829,11.9615082 C13.5676669,11.9615082 13.237862,13.6498994 13.237862,15.3925291 L13.237862,22 L9.0903683,22 L9.0903683,8.64071385 L13.0707725,8.64071385 L13.0707725,10.4673257 L13.1276354,10.4673257 C13.6813927,9.41667396 15.0356049,8.3091593 17.0555507,8.3091593 C21.2599073,8.3091593 22.0367422,11.0753215 22.0367422,14.6734319 L22.0367422,22 Z M4.40923804,6.81585163 C3.07514653,6.81585163 2,5.73720584 2,4.40748841 C2,3.07864579 3.07514653,2 4.40923804,2 C5.73720584,2 6.81585163,3.07864579 6.81585163,4.40748841 C6.81585163,5.73720584 5.73720584,6.81585163 4.40923804,6.81585163 L4.40923804,6.81585163 Z M6.48604672,22 L2.32980492,22 L2.32980492,8.64071385 L6.48604672,8.64071385 L6.48604672,22 Z'
            ></path>
          </svg>
        </span>
      </a>
      <div className='flex mt-4 md:mt-6 lg:mt-2 2xl:mt-8 gap-x-2 md:gap-x-3 items-start justify-center'>
        <div>
          <p className='font-bold text-lg xl:text-xl 2xl:text-2xl text-center'>{name}</p>
          <p className='font-plainNormal mt-1 2xl:text-base text-sm opacity-60 text-center'>
            {designation}
          </p>
        </div>
      </div>
    </div>
  );
};

const teams = [
  {
    id: 1,
    img: Narayan,
    name: 'Narayan Lal Gurjar',
    designation: 'Founder & Chief Executive Officer',
    linkedIn: 'https://www.linkedin.com/in/narayan-lal-gurjar-88a533141/',
  },
  {
    id: 2,
    img: Puran,
    name: 'Puran Singh Rajput',
    designation: 'Co-Founder & Chief Operating Officer',
    linkedIn: 'https://www.linkedin.com/in/puransinghrajput/',
  },
  {
    id: 3,
    img: Ankit,
    name: 'Ankit Jain',
    designation: 'Co-Founder & Business Development Officer',
    linkedIn: ' https://www.linkedin.com/in/ankitjain-efpolymer/',
  },
  {
    id: 4,
    img: Kunihiro,
    name: 'Kunihiro Shimoji',
    designation: 'Chief Operating Officer',
    linkedIn: 'https://www.linkedin.com/in/kunihiro-shimoji-23339052/',
  },
  {
    id: 5,
    img: Hiroshi,
    name: 'Hiroshi Yoshikawa',
    designation: 'Chief Financial Officer',
    linkedIn: 'https://www.linkedin.com/in/hiroshi-yoshikawa-45930b24/',
  },
];

export const Team = () => {
  return (
    <div className='blade-pt-sm blade-pb bg-brownMedium'>
      <div className='lg:container px-4'>
        <ContentTile
          title='Our team'
          center
          className='mx-auto max-w-xl'
          p2='The incredible team that drives the cause forward.'
        />
      </div>
      <div
        className='flex 2xl:max-w-screen-2xl max-w-screen-xl 2xl:w-[85%] w-[96%] mx-auto mt-12 2xl:mt-16 flex-wrap flex-row 
        gap-y-12 lg:gap-y-8 2xl:gap-y-16 lg:gap-x-0 2xl:gap-x-20 items-center justify-center'
      >
        {teams.map((team, index) => (
          <Card
            key={index}
            img={team.img}
            name={team.name}
            designation={team.designation}
            link={team.linkedIn}
          />
        ))}
      </div>
    </div>
  );
};
