import { EcoFriendlyHydrogel } from './ecoFriendlyHydrogel';
import { Impact } from './impact';
import { Steps } from './steps';
import { Testimonials } from './testimonials';
import { FAQ } from './faq';
// import { Applications } from './applications';
import Banner from '../../modules/core/banner';
import BannerLogo from '../../assets/agriculture/banner_logo.svg';
import GetInTouch from '../../modules/core/get-in-touch';
import './index.css';
import { useEffect } from 'react';

const Agriculture = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Banner
        title='Empowering growers'
        subtitle="Enhance your plant’s growth <br class='' /> organically."
        pageTitle='Agriculture'
        logotext={BannerLogo}
        className='agriculture-banner-wrapper'
      />
      <EcoFriendlyHydrogel />
      <Impact />
      <Steps />
      {/* <Applications /> */}
      <Testimonials />
      <FAQ />
      <GetInTouch bg='#F1EDE6' />
    </>
  );
};

export default Agriculture;
