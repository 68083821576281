import ContentTile from '../../modules/atoms/contentTile';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import BheruSingh from '../../assets/agriculture/testimonials/bheru-singh.webp';
import KalyanSingh from '../../assets/agriculture/testimonials/kalyan-singh.webp';
import KamalSingh from '../../assets/agriculture/testimonials/kamal-singh.webp';
import './index.css';
import 'swiper/css/navigation';

const slides = [
  {
    id: 1,
    img: BheruSingh,
    name: '- Bheru Singh',
    message:
      '“I have seen a lot of difference in my crops since I started using EF Polymer. My maize crop is growing well despite no rain for a long period.”',
  },
  {
    id: 2,
    img: KalyanSingh,
    name: '- Kalyan Singh',
    message:
      '“With the help of EF Polymer, my crops are flourishing even without water, whereas earlier these crops used to get destroyed. I am happy with the growth of my crops and will continue to use EF Polymer.”',
  },
  {
    id: 3,
    img: KamalSingh,
    name: '- Kamal Singh',
    message:
      '“EF Polymer kept my crops safe and healthy, enabling them to survive for a long time without water.”',
  },
];

export const Testimonials = () => {
  return (
    <div className='blade-pt-sm xl:blade-pt blade-pb testimonials-slider-wrapper'>
      <ContentTile
        label='Testimonials'
        center
        className='mx-auto max-w-2xl'
        title='Success Stories'
        p2='We are growing with their belief!'
      />
      <div className='animate-box-fade-in pt-8 md:pt-12'>
        <Swiper
          id='swiper-news'
          slideActiveClass='testimonials-active-slide'
          slideNextClass='opacity-50'
          slidePrevClass='opacity-50'
          centeredSlides
          autoplay
          autoHeight={false}
          loop={false}
          speed={1200}
          grabCursor
          modules={[Navigation]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          slidesPerView={1.1}
          spaceBetween={20}
          breakpoints={{
            428: {
              spaceBetween: 30,
              slidesPerView: 1.3,
            },
            768: {
              spaceBetween: 20,
              slidesPerView: 1.2,
            },
            1024: {
              spaceBetween: 20,
              slidesPerView: 1.4,
            },
            1366: {
              spaceBetween: 40,
              slidesPerView: 1.4,
            },
            1440: {
              spaceBetween: 60,
              slidesPerView: 1.6,
            },
            1536: {
              spaceBetween: 80,
              slidesPerView: 1.7,
            },
          }}
        >
          {slides.map((slide, i) => {
            return (
              <SwiperSlide
                key={i}
                className='rounded-3xl min-h-full !h-auto !transition-all !duration-300 border-2 border-green 
                  px-4 md:px-8 xl:px-12 pt-4 pb-6 md:py-8 overflow-hidden bg-white'
              >
                <div className='2xl:h-[26rem] xl:h-[24rem] lg:h-[23rem] md:h-[26rem] h-auto mx-auto flex flex-col-reverse md:flex-row items-center justify-between'>
                  <div className='w-full mt-8 md:w-[55%] xl:w-[48%]'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='42'
                      height='34'
                      viewBox='0 0 42 34'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_3346_406)'>
                        <path
                          d='M2.9834 30.3214C1.33608 28.4993 0.420898 25.8674 0.420898 22.4257C0.420898 15.9472 4.63072 8.65883 13.0504 0.358215L15.9789 3.79993C13.0504 7.4441 10.8539 10.2785 9.20661 12.7079C7.74233 15.3398 7.01018 17.7693 6.82715 20.1987C7.74233 19.7938 9.20661 19.5913 11.037 19.5913C13.0504 19.5913 14.8807 20.1987 16.162 21.4134C17.6263 22.6282 18.1754 24.2478 18.1754 26.2723C18.1754 28.4993 17.4432 30.1189 15.7959 31.3337C14.1486 32.5484 12.1352 33.1558 9.75572 33.1558C6.82715 33.1558 4.63072 32.1435 2.9834 30.3214ZM26.0459 30.5239C24.3986 28.7018 23.4834 26.0699 23.4834 22.6282C23.4834 19.1864 24.5816 15.7447 26.778 11.8981C28.9745 8.25392 32.0861 4.40729 36.1129 0.560669L39.0414 4.00239C35.9298 7.64656 33.7334 10.6834 32.2691 12.9104C30.8048 15.3398 30.0727 17.7693 29.8897 20.1987C30.9879 19.7938 32.2691 19.3889 34.0995 19.3889C36.1129 19.3889 37.9432 19.9963 39.2245 21.211C40.6888 22.4257 41.4209 24.0453 41.4209 25.8674C41.4209 27.892 40.5057 29.714 38.8584 30.9288C37.2111 32.1435 35.1977 32.9533 32.8182 32.9533C29.8897 33.3582 27.6932 32.5484 26.0459 30.5239Z'
                          fill='#006633'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_3346_406'>
                          <rect
                            width='41'
                            height='33'
                            fill='white'
                            transform='translate(0.420898 0.358215)'
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className='text-sm font-plainLight md:text-base mt-3 xl:mt-6'>
                      {slide.name}
                    </p>
                    <h4 className='font-plainNormal md:pr-12 xl:pr-0 text-base md:text-lg xl:text-xl 2xl:text-2xl mt-3 xl:mt-4 text-greenDark'>
                      {slide.message}
                    </h4>
                    <div className='mt-8'>
                      <a
                        className='cursor-pointer text-greenDark border-greenDark border bg-transparent outline-green outline-offset-4
                        hover:bg-greenDark hover:text-white
                        font-plainNormal px-8 py-3 rounded-full'
                        href='testimonial'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Watch the full video
                      </a>
                    </div>
                  </div>
                  <div className='h-[240px] sm:h-[300px] md:h-full rounded-xl w-full md:w-[45%] xl:w-[48%]'>
                    <img
                      className='w-full h-full rounded-xl md:object-cover object-cover'
                      src={slide.img}
                      alt={slide.name}
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          <div className='relative flex justify-center items-center md:gap-5 gap-3 md:pt-12 pt-10'>
            <button
              className='swiper-button-prev outline-green md:pl-2 hover:stroke-white stroke-greenDark pl-1 group w-6 h-6  md:p-3 p-2  
              rounded-full bg-white hover:bg-greenDark flex items-center justify-center '
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='2'
                className='h-6 w-6 xl:h-8 xl:w-8'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15.75 19.5L8.25 12l7.5-7.5'
                />
              </svg>
            </button>
            <button
              className='swiper-button-next outline-green md:pl-2 pl-1 w-6 h-6 rotate-180 hover:stroke-white stroke-greenDark md:p-3 p-2  
              rounded-full bg-white hover:bg-greenDark flex items-center justify-center group '
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='2'
                className='h-6 w-6 xl:h-8 xl:w-8'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15.75 19.5L8.25 12l7.5-7.5'
                />
              </svg>
            </button>
          </div>
        </Swiper>
      </div>
    </div>
  );
};
