import React from 'react';

export default function Cards() {
  const data = [
    {
      title: '1. Superabsorbent hydrogel',
      des: 'Acts as a constant source of moisture',
      imgAlt: 'icon for superabsorbent Hydrogel',
    },
    {
      title: '2. Soil conditioner',
      des: 'Rejuvenates the soil organically',
      imgAlt: 'icon for Soil Conditioner',
    },
    {
      title: '3. Nourishment provider',
      des: 'Supplements essential micronutrients',
      imgAlt: 'icon for Nourishment provider',
    },
    {
      title: '4. Supplements fertilizers',
      des: 'Enhances the application of fertilizers',
      imgAlt: 'icon for Supplements Fertilizers',
    },
    {
      title: '5. Improves retention',
      des: 'Eliminates leaching of nutrients and water',
      imgAlt: 'icon for improves retention',
    },
    {
      title: '6. Bio-degradable polymer',
      des: 'Bio-degrades and generates nothing but nutrients',
      imgAlt: 'icon for bio-degradable polymers',
    },
    {
      title: '7. Save more',
      des: 'Saves input cost, labor, and water',
      imgAlt: 'icon for save more',
    },
    {
      title: '8. Grow more',
      des: 'Boosts yield, crop quality, and growth',
      imgAlt: 'icon for Grow more',
    },
  ];

  const ProductsCard = ({ title, des }) => {
    return (
      <div className=' bg-white rounded-lg h-full hover:shadow-md transition-all duration-300'>
        <div className='leaves h-full px-6 pt-4 pb-12'>
          <div className='flex flex-col gap-2'>
            <h4 className='text-greenDark font-object font-bold mt-4'>{title}</h4>
            <p className='opacity-60'>{des}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className='mx-auto max-w-[1366px] grid lg:grid-cols-4 md:grid-cols-3 grid-col-1 gap-6'>
        {data.map((item, i) => {
          return (
            <div key={i}>
              <ProductsCard title={item.title} des={item.des} link='/' />
            </div>
          );
        })}
      </div>
    </div>
  );
}
