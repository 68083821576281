import React from 'react';
import { NavLink } from 'react-router-dom';
import HeadquaterIcon from '../../../assets/globals/headquarter_icon.svg';
import RegionIcon from '../../../assets/globals/regional_icon.svg';
import './footer.css';
import YoutubeLogo from '../../../assets/globals/youtube_logo.svg';
import InstaLogo from '../../../assets/globals/instagram_logo.svg';
import LinkedInLogo from '../../../assets/globals/linkedin_logo.svg';
import TwitterLogo from '../../../assets/globals/twitter_logo.svg';
import FBLogo from '../../../assets/globals/fb_logo.svg';

export default function Footer() {
  return (
    <footer className='text-lightGray bg-greenDark pt-20  selection:bg-white selection:text-dark border-none lg:pb-24 2xl:pb-32'>
      <div className='container'>
        <div className='lg:flex grid justify-between gap-8 lg:gap-4'>
          <div className='lg:flex items-start hidden max-w-[300px]'>
            <span className='h-full items-start mr-2 -mt-[12px] md:-mt-[18px] flex'>
              <img
                className='w-14 h-14 md:w-16 md:h-16'
                src={HeadquaterIcon}
                aria-hidden
                alt='Headquarter icon'
              />
            </span>

            <div>
              <h5 className='text-white font-bold flex items-center'> Headquarter, Japan</h5>
              <p className='mt-2 opacity-70 font-plainLight tracking-wide text-white'>
                1919-1 Innovation Square Incubator, Tancha, Onna Village, Kunigami District,
                Okinawa, Japan 904-0495
              </p>
              {/* <p className="opacity-70 mt-2 font-plainLight tracking-wide text-white">
                support@efpolymer.com
              </p>
              <p className="opacity-70 mt-2 font-plainLight tracking-wide text-white">
                +81-50-3196-8761
              </p> */}
            </div>
          </div>

          <div className='relative h-40 w-40 '>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              id='white-logo'
              className='absolute h-10 top-0 left-0 right-0 bottom-0 m-auto'
              viewBox='0 0 48 42'
              fill='none'
            >
              <path
                d='M47.8475 18.3872C47.7972 18.1162 47.6908 18.0155 47.4063 18.0136C46.0589 18.0137 44.7174 18.1913 43.4163 18.542C41.996 18.9156 40.6377 19.4343 39.4708 20.3595C38.7162 20.9557 38.1396 21.6777 37.9228 22.6416C37.7874 23.2417 37.8435 24.0856 38.0486 24.434C38.751 23.05 39.9836 21.8674 40.6822 21.8558C39.7147 23.3365 38.6755 24.8173 37.7506 26.3657C36.8257 27.9142 36.2858 29.666 35.7034 31.3867C35.6347 31.2911 35.5829 31.1844 35.5505 31.0712C35.2777 30.2331 35.0068 29.395 34.732 28.5549C33.9805 26.1377 32.4853 24.0195 30.4595 22.5023C29.3546 21.6758 28.2768 20.8144 27.1835 19.9724C27.1113 19.9298 27.033 19.8985 26.9513 19.8795L27.0693 19.7479C28.2555 20.2124 29.4881 20.5821 30.5001 21.4899C28.8108 16.6219 23.327 14.7153 19.4899 16.8561C19.4861 16.8779 19.4861 16.9001 19.4899 16.9219C20.0975 18.4975 20.7844 20.0344 21.8409 21.3738C22.3116 21.9999 22.9029 22.5255 23.5798 22.9194C24.2568 23.3133 25.0058 23.5677 25.7825 23.6675C27.2029 23.8611 28.6057 23.7062 29.997 23.4256C30.0867 23.3949 30.1839 23.394 30.2742 23.4229C30.3644 23.4519 30.443 23.5091 30.4982 23.5862C30.9665 24.1959 31.4831 24.7727 31.8953 25.4173C33.0872 27.2852 33.6929 29.3776 34.1283 31.528C34.1443 31.6613 34.1324 31.7964 34.0934 31.9248C32.9634 36.4716 29.0101 39.9526 24.3719 40.5139V41.1848H34.4166C35.0842 41.1848 35.2177 41.0725 35.326 40.4106C35.6279 38.6046 35.9065 36.7987 36.2297 34.9908C36.6283 32.7087 37.0211 30.4209 37.7235 28.2162C37.9833 27.4957 38.2811 26.7895 38.6156 26.1006C38.722 25.8606 38.8749 25.8025 39.1535 25.9225C39.9045 26.2472 40.7243 26.3803 41.5394 26.3096C43.4995 26.1451 45.0746 25.2353 46.315 23.7565C47.6211 22.2197 48.2287 20.4408 47.8475 18.3872Z'
                fill='white'
              />
              <path
                d='M19.2982 40.4998H16.8775C16.7904 40.4998 16.7053 40.5095 16.6105 40.4998L16.6105 0.776011H17.0478C19.0718 0.776011 21.0978 0.741182 23.1218 0.789557C24.0356 0.791884 24.9455 0.907578 25.8308 1.13399C30.4633 2.43818 33.2981 5.46841 34.2907 10.1821C34.3941 10.8388 34.4587 11.5011 34.4842 12.1655C34.5036 12.3802 34.5868 12.5118 34.8093 12.5157C35.0318 12.5196 35.1324 12.3861 35.1363 12.1732C35.1363 12.0939 35.1363 12.0145 35.1363 11.9352V0.712158C35.1363 0.145201 35.1054 0.112305 34.5558 0.112305H1.15746C0.931059 0.112305 0.770455 0.205187 0.784 0.447063C0.797545 0.688939 0.950411 0.777948 1.171 0.777948C1.39159 0.777948 1.60057 0.777948 1.81536 0.777948C2.37537 0.764765 2.91909 0.967014 3.33435 1.34297C3.96516 1.89058 4.14318 2.6104 4.14318 3.41536C4.14318 14.8964 4.14318 26.3729 4.14318 37.8449C4.14318 37.9804 4.14318 38.1178 4.14318 38.2532C4.13135 38.7836 3.93522 39.2933 3.58847 39.6948C3.24172 40.0962 2.76599 40.3644 2.243 40.4533C1.87921 40.5211 1.49608 40.4707 1.13036 40.5249C1.05737 40.546 0.990481 40.5842 0.935273 40.6364C0.880066 40.6885 0.838151 40.7532 0.813024 40.8249C0.762713 41.0184 0.905901 41.1577 1.13036 41.1596H1.36644C6.73933 41.1686 12.1135 41.177 17.489 41.1848H19.306L19.2982 40.4998Z'
                fill='white'
              />
              <rect x='16.6118' y='40.5034' width='8.21985' height='0.68159' fill='white' />
              <rect x='15.4214' y='38.9263' width='1.19029' height='2.25877' fill='white' />
            </svg>
            <svg
              className='h-full animate-spin-slower'
              viewBox='0 0 182 183'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M112.178 10.8943L98.68 8.50327L98.2928 10.6892L109.277 12.635L108.091 19.3295L98.4732 17.6258L98.086 19.8118L107.704 21.5155L106.639 27.5269L95.9282 25.6296L95.541 27.8155L108.766 30.1582L112.178 10.8943Z'
                fill='#fff'
              />
              <path
                d='M84.0711 8.46804L81.548 8.85762L82.9242 17.7708L74.2305 19.1131L74.5692 21.3071L83.263 19.9648L84.1946 25.9983L74.4038 27.51L74.7426 29.704L87.0564 27.8027L84.0711 8.46804Z'
                fill='#fff'
              />
              <path
                d='M44.2805 22.2706L42.3484 23.809L47.0675 29.7355L42.8994 33.0544C39.6431 35.6472 38.8418 39.2295 41.1408 42.1167C43.4527 45.0647 47.0237 45.0945 50.3668 42.4325L56.467 37.5752L44.2805 22.2706ZM48.4676 31.4939L53.152 37.377L48.984 40.6958C46.7697 42.459 44.5293 42.3628 43.0946 40.561C41.6772 38.7809 42.0852 36.5759 44.2995 34.8128L48.4676 31.4939Z'
                fill='#fff'
              />
              <path
                d='M18.2383 49.3573C15.1236 54.7046 16.6575 60.6721 21.9808 63.7728C27.2561 66.8456 33.2276 65.2498 36.3422 59.9026C39.4569 54.5553 37.8991 48.5738 32.6238 45.501C27.3005 42.4003 21.353 44.0101 18.2383 49.3573ZM20.1566 50.4747C22.5171 46.4223 27.2044 45.2668 31.3528 47.6831C35.5011 50.0994 36.7844 54.7328 34.424 58.7852C32.0635 62.8376 27.4141 63.9831 23.2657 61.5668C19.1174 59.1505 17.7961 54.5271 20.1566 50.4747Z'
                fill='#fff'
              />
              <path
                d='M9.5944 71.9917L7.53544 84.1957L9.72451 84.5651L11.3634 74.8511L28.4654 77.7364L28.8855 75.2463L9.5944 71.9917Z'
                fill='#fff'
              />
              <path
                d='M8.24876 103.612L8.65897 106.132L15.7254 104.981L29.0966 109.918L28.6596 107.234L17.9255 103.47L17.8809 103.197L26.8494 96.1135L26.4214 93.4841L15.3152 102.461L8.24876 103.612Z'
                fill='#fff'
              />
              <path
                d='M13.9576 126.077L15.2995 128.249L29.2041 119.658L29.3354 119.87L18.8878 134.057L20.1569 136.11L37.5567 133.123L37.6879 133.335L23.7598 141.941L25.0871 144.09L41.7301 133.806L39.6443 130.43L22.5861 133.337L22.4548 133.125L32.7011 119.193L30.6006 115.794L13.9576 126.077Z'
                fill='#fff'
              />
              <path
                d='M36.4189 155.408L47.5341 163.431L48.8335 161.631L39.7883 155.102L43.7675 149.589L51.6877 155.306L52.987 153.506L45.0668 147.789L48.64 142.839L57.4602 149.206L58.7595 147.406L47.8693 139.545L36.4189 155.408Z'
                fill='#fff'
              />
              <path
                d='M60.6656 169.967L63.0956 170.654L65.1793 163.284L68.6507 164.265L73.0827 173.478L76.1535 174.346L71.4389 164.91L71.7593 165C75.3028 165.829 78.447 164.295 79.353 161.091C80.3344 157.62 78.2715 154.614 74.1859 153.459L65.988 151.141L60.6656 169.967ZM65.7832 161.148L67.814 153.965L73.5819 155.595C76.279 156.358 77.542 158.214 76.923 160.404C76.2964 162.62 74.2482 163.541 71.5511 162.778L65.7832 161.148Z'
                fill='#fff'
              />
              <path
                d='M106.01 174.123L119.24 170.534L118.659 168.391L107.892 171.312L106.112 164.751L115.539 162.193L114.958 160.051L105.531 162.608L103.932 156.716L114.431 153.868L113.85 151.725L100.887 155.242L106.01 174.123Z'
                fill='#fff'
              />
              <path
                d='M132.474 164.341L134.591 162.913L129.547 155.437L136.84 150.517L135.598 148.677L128.306 153.596L124.892 148.535L133.105 142.995L131.863 141.154L121.534 148.122L132.474 164.341Z'
                fill='#fff'
              />
              <path
                d='M162.589 134.896L163.681 132.681L156.886 129.331L159.242 124.552C161.083 120.819 160.282 117.237 156.971 115.604C153.624 113.923 150.38 115.418 148.49 119.251L145.042 126.244L162.589 134.896ZM154.87 128.337L148.125 125.011L150.482 120.232C151.733 117.694 153.801 116.826 155.867 117.844C157.908 118.851 158.478 121.019 157.226 123.558L154.87 128.337Z'
                fill='#fff'
              />
              <path
                d='M174.603 99.2953C175.142 93.1306 171.211 88.3858 165.074 87.8493C158.992 87.3176 154.27 91.3061 153.731 97.4708C153.192 103.636 157.15 108.383 163.232 108.914C169.369 109.451 174.064 105.46 174.603 99.2953ZM172.391 99.102C171.983 103.774 168.235 106.817 163.452 106.399C158.67 105.981 155.534 102.336 155.943 97.6642C156.351 92.9922 160.069 89.9745 164.851 90.3926C169.634 90.8107 172.799 94.4301 172.391 99.102Z'
                fill='#fff'
              />
              <path
                d='M172.778 75.1341L169.44 63.2163L167.302 63.8151L169.959 73.3013L153.258 77.9793L153.94 80.411L172.778 75.1341Z'
                fill='#fff'
              />
              <path
                d='M160.518 45.9566L159.073 43.8519L153.171 47.904L138.971 49.1365L140.51 51.3785L151.825 50.2084L151.982 50.4372L146.887 60.6668L148.395 62.8631L154.616 50.0087L160.518 45.9566Z'
                fill='#fff'
              />
              <path
                d='M145.781 28.0655L143.641 26.6726L134.724 40.3705L134.515 40.2343L137.92 22.9482L135.897 21.6311L121.43 31.7489L121.22 31.6127L130.153 17.8915L128.037 16.5138L117.363 32.9094L120.689 35.0744L134.881 25.1751L135.091 25.3113L131.758 42.281L135.107 44.4611L145.781 28.0655Z'
                fill='#fff'
              />
              <path
                d='M112.961 11.1048L99.4862 8.58339L99.0779 10.7655L110.043 12.8174L108.793 19.5001L99.1912 17.7035L98.7829 19.8856L108.384 21.6823L107.261 27.6831L96.5689 25.6823L96.1606 27.8644L109.362 30.3348L112.961 11.1048Z'
                fill='#fff'
              />
              <path
                d='M84.8213 8.26682L82.3302 8.68091L83.5861 16.2362L80.0274 16.8278L72.0922 10.3827L68.9441 10.906L77.2306 17.4333L76.9021 17.4879C73.3434 18.2483 71.1524 20.9755 71.6984 24.2604C72.29 27.8191 75.437 29.6589 79.6253 28.9627L88.0293 27.5658L84.8213 8.26682ZM83.9501 18.4262L85.1742 25.7899L79.2613 26.7728C76.4965 27.2324 74.5626 26.091 74.1895 23.8463C73.8118 21.5742 75.2724 19.8687 78.0372 19.4091L83.9501 18.4262Z'
                fill='#fff'
              />
            </svg>
          </div>

          <div className='flex lg:hidden max-w-[300px]'>
            <span className='h-full items-start mr-2 -mt-[8px] flex justify-center'>
              <img aria-hidden className='w-10 h-10' src={HeadquaterIcon} alt='Headquarter icon' />
            </span>
            <div>
              <h5 className='text-white font-bold'>Headquarter, Japan</h5>
              <p className='mt-2 opacity-70 text-white font-plainLight tracking-wide'>
                1919-1 Innovation Square Incubator, Tancha, Onna Village, Kunigami District,
                Okinawa, Japan 904-0495
              </p>
              {/* <p className="opacity-70 mt-2 font-plainLight tracking-wide text-white">
                support@efpolymer.com
              </p>
              <p className="opacity-70 mt-2 font-plainLight tracking-wide text-white">
                +81-50-3196-8761
              </p> */}
            </div>
          </div>

          <div className='flex max-w-xs lg:translate-x-12 2xl:translate-x-10'>
            <span className='h-full items-start mr-2 -mt-[12px] md:-mt-[16px] flex'>
              <img
                aria-hidden
                className='w-14 h-14 md:w-16 md:h-16'
                src={RegionIcon}
                alt='Regional icon'
              />
            </span>
            <div>
              <h5 className='text-white font-bold'>Regional Office, India</h5>
              <p className='mt-2 opacity-70 font-plainLight tracking-wide text-white'>
                408, Bhopalpura, C-1 Road, Opposite Gattani Hospital Lane,Udaipur, Rajasthan, India
                313001
              </p>
              {/* <p className="opacity-70 mt-2 font-plainLight tracking-wide text-white">
                support@efpolymer.com
              </p>
              <p className="opacity-70 mt-2 font-plainLight tracking-wide text-white">
                +81-50-3196-8761
              </p> */}
            </div>
          </div>
        </div>
        <div className=' flex flex-wrap items-center justify-center gap-y-2 gap-x-10 mt-16'>
          <NavLink
            aria-label='Home'
            className={`tracking-wide hover:text-yellow text-greenLight ${({ isActive }) =>
              isActive ? 'active' : ''}`}
            to='/'
            text='Culture'
          >
            Home
          </NavLink>

          <NavLink
            aria-label='Technology'
            className={`tracking-wide text-greenLight hover:text-yellow ${({ isActive }) =>
              isActive ? 'active' : ''}`}
            to='/technology'
            text='Technology'
          >
            Technology
          </NavLink>
          <NavLink
            aria-label='Agriculture'
            className={`tracking-wide hover:text-yellow text-greenLight ${({ isActive }) =>
              isActive ? 'active' : ''}`}
            to='/agriculture'
            text='Agriculture'
          >
            Agriculture
          </NavLink>
          <NavLink
            aria-label='About us'
            className={`tracking-wide text-greenLight hover:text-yellow ${({ isActive }) =>
              isActive ? 'active' : ''}`}
            to='/about-us'
            text='About'
          >
            About Us
          </NavLink>
          <NavLink
            aria-label='Resources'
            className={`tracking-wide text-greenLight hover:text-yellow ${({ isActive }) =>
              isActive ? 'active' : ''}`}
            to='/resources'
            text='Resources'
          >
            Resources
          </NavLink>
          <NavLink
            aria-label='Contact us'
            className={`tracking-wide hover:text-yellow text-greenLight ${({ isActive }) =>
              isActive ? 'active' : ''}`}
            to='/contact-us'
            text='Contact us'
          >
            Contact Us
          </NavLink>
        </div>
        <hr className='mt-10 text-white opacity-60' />
        <div className='flex py-10 justify-between gap-x-6 lg:flex-row flex-col-reverse items-center  w-container border-b-1 border-solid border-lightGray border-opacity-10'>
          <div className='lg:basis-80 text-greenLight text-sm text-opacity-70'>
            2023 EF Polymer. All rights reserved
          </div>
          <ul className='flex items-start flex-nowrap my-4 gap-x-8'>
            <li>
              <a
                aria-label='Facebook profile'
                target='_blank'
                rel='noreferrer'
                className='text-base'
                href='https://www.facebook.com/efpolymer.india'
              >
                <span className='block  w-7 h-7'>
                  <img src={FBLogo} alt='facebook logo' className='w-full h-full' />
                </span>
              </a>
            </li>
            <li>
              <a
                aria-label='Twitter profile'
                target='_blank'
                rel='noreferrer'
                className='text-greenLight hover:text-opacity-100 text-base'
                href='https://twitter.com/efpolymer'
              >
                <span className=' block w-7 h-7'>
                  <img src={TwitterLogo} alt='twitter logo' className='w-full h-full' />
                </span>
              </a>
            </li>
            <li>
              <a
                aria-label='Linkedin profile'
                target='_blank'
                rel='noreferrer'
                className='text-greenLight hover:text-opacity-100 text-base'
                href='https://www.linkedin.com/company/ef-polymer-india/?viewAsMember=true'
              >
                <span className='block w-7 h-7'>
                  <img src={LinkedInLogo} alt='linkedin logo' className='w-full h-full' />
                </span>
              </a>
            </li>
            <li>
              <a
                aria-label='Instagram profile'
                target='_blank'
                rel='noreferrer'
                className='text-greenLight hover:text-opacity-100 text-base'
                href='https://www.instagram.com/efpolymer_india/'
              >
                <span className='block  w-7 h-7'>
                  <img src={InstaLogo} className='w-full h-full' alt='Instagram logo' />
                </span>
              </a>
            </li>
            <li>
              <a
                aria-label='Youtube profile'
                target='_blank'
                rel='noreferrer'
                className='text-greenLight hover:text-opacity-100 text-base'
                href='https://www.youtube.com/@EFPolymer'
              >
                <span className='block w-7 h-7'>
                  <img
                    className='w-full h-full object-contain'
                    src={YoutubeLogo}
                    alt='Youtube logo'
                  />
                </span>
              </a>
            </li>
          </ul>
          {/* <div className="lg:basis-80 flex justify-end text-greenLight text-sm text-opacity-70">
            Privacy Policy
          </div> */}
        </div>
      </div>
    </footer>
  );
}
