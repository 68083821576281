import React from 'react';
// import waves from "../../../assets/homepage/waves.svg";
import { ContentTile, SectionLink } from '../../../modules/atoms';
import gates_logo from '../../../assets/homepage/collaborations/bill gates_logo.png';
import imagine_h20_logo from '../../../assets/homepage/collaborations/imagine h20_logo.png';
import marico_logo from '../../../assets/homepage/collaborations/marico_logo.png';
import niam_logo from '../../../assets/homepage/collaborations/niam_logo.png';
import oist_logo from '../../../assets/homepage/collaborations/oist_logo.png';
import pwc_logo from '../../../assets/homepage/collaborations/pwc_logo.png';
import alpha_logo from '../../../assets/homepage/collaborations/social alpha_logo.png';
import tata from '../../../assets/homepage/collaborations/tata trusts_logo.png';
import ef from '../../../assets/globals/efp_logo.svg';
import './styles.css';

const data = [
  {
    src: imagine_h20_logo,
    alt: 'Imagine H2O logo',
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7140946652739133440',
  },
  {
    src: pwc_logo,
    alt: 'PWC logo',
    link: 'https://www.pwc.com/gx/en.html',
  },
  {
    src: gates_logo,
    alt: 'Bill Gates and Melinda foundation logo',
    link: 'https://www.gatesfoundation.org/',
  },
  {
    src: tata,
    alt: 'Tata trusts logo',
    link: 'https://www.tatatrusts.org/',
  },
  {
    src: niam_logo,
    alt: 'NIAM logo',
    link: 'https://www.linkedin.com/posts/niam-agri-business-incubator-nabi-2853b1180_startup-funding-agriculture-activity-7062393806943379457-26R_',
  },
  {
    src: oist_logo,
    alt: 'OIST logo',
    link: 'https://www.oist.jp/news-center/news/2022/3/9/oist-grown-company-ef-polymer-wins-top-prize-japans-2021-green-startup-pitch#:~:text=Now%20headquartered%20in%20the%20incubator,parts%20of%20fruits%20and%20vegetables',
  },
  {
    src: alpha_logo,
    alt: 'Social alpha logo',
    link: 'https://www.linkedin.com/posts/ef-polymer-india_agritech-startups-agriculture-activity-7107757438526717952-HQOz/',
  },
  {
    src: marico_logo,
    alt: 'Marico logo',
    link: 'https://www.linkedin.com/posts/marico-innovation-foundation_mentorshipmatters-innovation-entrepreneurship-activity-7095387188934152192-kRXO?trk=public_profile_like_view',
  },
];

const CardsLogo = ({ src, alt, id, link }) => {
  return (
    <div>
      <a
        href={link}
        aria-label={alt}
        target='_blank'
        rel='noreferrer'
        key={id}
        className={`flex-1 rounded-xl bg-gradient-to-br from-green to-brownLight group h-fit grid place-content-center outline-green`}
      >
        <div className='m-[1px] bg-[#FDF9F5] rounded-xl'>
          <div className={`md:p-6 2xl:h-36 h-28 flex justify-center items-center`}>
            <div className='h-full pl-1'>
              <img
                className='h-full object-contain group-hover:scale-105 transition-all duration-500'
                src={src}
                alt={alt}
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

const CardsLogoReverse = ({ src, alt, id, link }) => {
  return (
    <div>
      <a
        href={link}
        aria-label={alt}
        target='_blank'
        rel='noreferrer'
        key={id}
        className={`flex-1 rounded-xl bg-gradient-to-bl from-green to-brownLight group h-fit grid place-content-center outline-green`}
      >
        <div className='m-[1px] bg-[#FDF9F5] rounded-xl'>
          <div className={`md:p-6 2xl:h-36 h-28 flex justify-center  items-center`}>
            <div className='h-full pl-1 '>
              <img
                className='h-full object-contain group-hover:scale-105 transition-all duration-500'
                src={src}
                alt={alt}
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

const Collaborations = () => {
  return (
    <section className='blade-pt blade-pb'>
      <div className='container'>
        <ContentTile
          label='Collaborations'
          className='max-w-xl mx-auto'
          center
          title='Better together'
          p2='Learn from other businesses who have been there. Your company operates independently yet benefits from shared resources like capital, community, and expertise.'
        />
      </div>
      <div className=' flex md:flex-row flex-col gap-3 2xl:w-[75%] w-[90%] relative mx-auto justify-between collab-logo-wrapper blade-mt-sm blade-mb-sm'>
        <div className='flex flex-col gap-3 lg:w-[35%] 2xl:w-[40%] border border-transparent'>
          <div className='flex justify-end gap-3 mx-auto animate-box-fade-in 2xl:ml-32 xl:ml-20'>
            {data.slice(0, 2).map((item, index) => {
              return (
                <CardsLogo key={index} id={index} src={item.src} alt={item.alt} link={item.link} />
              );
            })}
          </div>

          <div className='flex justify-end gap-3 mx-auto animate-box-fade-in 2xl:mr-32 xl:mr-20'>
            {data.slice(2, 4).map((item, index) => {
              return (
                <CardsLogo key={index} id={index} src={item.src} alt={item.alt} link={item.link} />
              );
            })}
          </div>
        </div>

        <div className='flex flex-col gap-3 lg:w-[35%] 2xl:w-[40%]'>
          <div className='flex justify-end gap-3 mx-auto  animate-box-fade-in 2xl:mr-32 xl:mr-20'>
            {data.slice(4, 6).map((item, index) => {
              return (
                <CardsLogoReverse
                  key={index}
                  id={index}
                  src={item.src}
                  alt={item.alt}
                  link={item.link}
                />
              );
            })}
          </div>

          <div className='flex justify-end gap-3 mx-auto animate-box-fade-in 2xl:ml-32 xl:ml-20'>
            {data.slice(6, 8).map((item, index) => {
              return (
                <CardsLogoReverse
                  key={index}
                  id={index}
                  src={item.src}
                  alt={item.alt}
                  link={item.link}
                />
              );
            })}
          </div>
        </div>

        <div className=' md:w-[20rem] rounded-full w-[12rem] md:h-[20rem] h-[12rem] top-0 left-0 right-0 bottom-0 m-auto md:block hidden absolute flex-1 mix-blend-multiply'>
          <div className='circle-wrapper-wave z-50'>
            <div className='svg-box md:p-3 p-5'>
              <div>
                <img className='h-full' src={ef} alt='EF-Polymer logo' />
              </div>
            </div>

            {/* <div className="circle delay1"></div> */}
            <div className='circles delay2'></div>
            <div className='circles delay3'></div>
            <div className='circles delay4'></div>

            <div className='bg-blur absolute rounded-full top-0 left-0 right-0 bottom-0 h-full w-full '></div>
          </div>
        </div>
      </div>

      <div className='flex justify-center mt-8 animate-box-fade-in'>
        <SectionLink
          title="Let's Collaborate"
          className='px-10 py-3 font-bold'
          sectionId='get-in-touch'
        />
      </div>
    </section>
  );
};

export default Collaborations;
