import { useState } from 'react';
import { ContentTile } from '../../modules/atoms';

const data = [
  {
    id: 1,
    aspect: 'Raw materials',
    conventional: [{ id: 1, point: 'Acrylic acid (petroleum-based).' }],
    organic: [{ id: 1, point: 'Crop residues (orange, banana peels).' }],
  },
  {
    id: 2,
    aspect: 'Environmental Impact (Manufacturing + Disposal)',
    conventional: [
      {
        id: 1,
        point: 'Energy-intensive manufacturing with significant CO2 emissions.',
      },
      {
        id: 2,
        point: 'Incineration during disposal requires high energy and emits CO2.',
      },
    ],
    organic: [
      { id: 1, point: 'Made from crop residues with minimal CO2 emissions.' },
      {
        id: 2,
        point: 'Chemical-free manufacturing process with low emissions.',
      },
    ],
  },
  {
    id: 3,
    aspect: 'Agricultural Compatibility',
    conventional: [
      { id: 1, point: 'Contains water, making incineration energy-intensive.' },
      {
        id: 2,
        point: 'High water absorption can harm crops by depriving them of water.',
      },
    ],
    organic: [
      { id: 1, point: 'No harmful chemical residues from incineration.' },
      {
        id: 2,
        point: 'Optimized for agriculture with suitable water absorption.',
      },
    ],
  },
  {
    id: 4,
    aspect: 'Water Absorption and Release',
    conventional: [
      {
        id: 1,
        point: 'Not biodegradable, contaminating soil and groundwater.',
      },
      { id: 2, point: 'Limited repeat water absorption and release.' },
    ],
    organic: [
      {
        id: 1,
        point: 'Fully biodegrades in one year, leaving no harmful residues.',
      },
      { id: 2, point: 'Repeated water absorption and release for 6 months.' },
    ],
  },
];

const DesktopView = () => {
  return (
    <table className='mt-16 w-full hidden lg:block'>
      <thead>
        <tr className='bg-greenDark'>
          <th className='font-normal text-xl xl:text-2xl py-6 px-8 xl:px-16 text-left text-white'>
            Aspect
          </th>
          <th className='font-normal text-xl xl:text-2xl px-8 xl:px-16 text-left text-white'>
            Conventional SAP
          </th>
          <th className='font-normal text-xl xl:text-2xl px-8 xl:px-16 text-left border-r text-white'>
            100% Organic SAP
          </th>
        </tr>
      </thead>

      <tbody>
        {data.map((item) => {
          return (
            <tr key={item.id} className='bg-brownMedium  border-b border-gray border-opacity-40'>
              <td className='text-lg xl:text-xl border-l border-opacity-40 border-gray font-plainSemibold text-greenDark py-12 px-8 xl:px-16'>
                {`${item.id}. ${item.aspect}`}
              </td>
              <td className='px-12 xl:px-16 border-l border-r border-gray border-opacity-40 py-12'>
                <ul className='list-disc flex flex-col gap-y-6'>
                  {item.conventional.map((item, index) => (
                    <li className='text-lg xl:text-xl font-normal' key={index}>
                      {item.point}
                    </li>
                  ))}
                </ul>
              </td>
              <td className='px-12 xl:px-16 py-12 border-r border-gray border-opacity-40'>
                <ul className='list-disc gap-y-6 flex flex-col'>
                  {item.organic.map((item, index) => (
                    <li key={index} className='text-lg xl:text-xl font-normal'>
                      {item.point}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const MobileView = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className='grid lg:hidden w-full gap-y-6 flex-col mt-8 md:mt-16'>
      {data.map((item, index) => (
        <div className='w-full' key={item.id}>
          <div
            onClick={() => {
              setSelected(index);
            }}
            className={`flex w-full rounded-t-lg py-4 border px-4 sm:px-6 md:px-8 transition-all duration-300 border-greenDark items-center justify-between cursor-pointer ${
              selected === index
                ? 'bg-greenDark cursor-auto rounded-b-0'
                : 'bg-transparent rounded-b-lg'
            }`}
          >
            <h3
              className={`${
                selected === index ? 'text-white' : 'text-greenDark'
              } font-plainSemibold text-lg sm:text-xl md:text-[22px] text-left max-w-md transition-all duration-500`}
            >
              {item.aspect}
            </h3>

            <span className={`${selected === index ? 'stroke-white' : 'stroke-greenDark'} `}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='inherit'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='inherit'
                className={`w-6 sm:w-8 md:w-10 h-6 sm:h-8 md:h-10 transition-all duration-500 ${
                  selected === index ? 'rotate-0' : 'rotate-[135deg]'
                }`}
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
              </svg>
            </span>
          </div>
          {/* open part */}
          <div
            className={`bg-brownMedium rounded-b-lg grid transition-all duration-500 delay-300 grid-rows-[0fr] ${
              selected === index
                ? 'grid-rows-[1fr] py-8 px-4 sm:px-6 md:px-8 border border-greenDark'
                : ''
            }`}
          >
            <div className='min-h-0 overflow-hidden'>
              <p className='font-plainSemibold text-lg md:text-xl text-greenDark'>
                Conventional SAP
              </p>
              <ul className='list-disc mt-4 flex flex-col gap-y-2'>
                {item.conventional.map((item, index) => (
                  <li key={index} className='font-normal text-base md:text-lg'>
                    {item.point}
                  </li>
                ))}
              </ul>

              <p className='font-plainSemibold mt-8 text-lg md:text-xl text-greenDark'>
                100% Organic SAP
              </p>
              <ul className='list-disc mt-4 flex flex-col gap-y-2'>
                {item.organic.map((item, index) => (
                  <li key={index} className='font-normal text-base md:text-lg'>
                    {item.point}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const SPAComparizon = () => {
  return (
    <div className='blade-pt-sm blade-pb bg-brownLight'>
      <div className='container'>
        <ContentTile
          center
          className='mx-auto max-w-3xl'
          title='Choosing the right SAP Conventional or 100% Organic?'
        />
        <DesktopView />
        <MobileView />
      </div>
    </div>
  );
};
