import React from 'react';

export default function Info() {
  return (
    <section className='bg-cream px-3 blade-pt-sm lg:blade-pt blade-pb-sm lg:blade-pb'>
      <div className='2xl:max-w-screen-2xl max-w-screen-[1336px] 2xl:w-[85%] w-[96%] mx-auto flex flex-col lg:flex-row gap-y-8 gap-x-8 overflow-hidden'>
        <div className='flex-1 flex items-start justify-center gap-6 flex-col rounded-3xl bg-greenDark p-8 md:p-10 lg:px-10 lg:py-12 xl:p-16 2xl:p-20'>
          <div className='flex flex-col gap-1 items-start'>
            <span className='text-xl lg:text-2xl font-plainSemibold text-yellow'>Email</span>
            <a className=' ' href='mailto:support@efpolymer.com'>
              <span className='text-white text-lg lg:text-xl font-plainLight'>
                efp-japan-office@efpolymer.com
              </span>
            </a>
          </div>

          <div className='flex flex-col gap-1 items-start'>
            <span className='text-xl lg:text-2xl font-plainSemibold text-yellow'>Phone</span>
            <a href='tel:+81-50-3196-8761'>
              <span className='text-white text-lg lg:text-xl font-plainLight'>
                +81-50-3196-8761
              </span>
            </a>
          </div>
          <div className='flex flex-col gap-1 items-start'>
            <span className='text-xl lg:text-2xl font-plainSemibold text-yellow'>Address</span>
            <span className='text-white sm:max-w-[300px] text-base font-plainLight'>
              1919-1 Innovation Square Incubator, Tancha, Onna Village, Kunigami District, Okinawa,
              Japan 904-0495
            </span>
          </div>
          <div className='mt-4 md:mt-6'>
            <a
              href='https://www.google.com/maps/place/EF+Polymer+Private+Limited/@24.632578,73.709489,17z/data=!4m6!3m5!1s0x3967e56d4998a791:0xa774c7ef9d0148ae!8m2!3d24.6325778!4d73.709489!16s%2Fg%2F11fl3_q84g?hl=en&entry=ttu'
              target='_blank'
              rel='noreferrer'
              className='leading-none md:pb-[12px] pb-2 md:pt-2 pt-1 px-6 text-greenDark text-xl font-bold hover:bg-yellow active:bg-yellow bg-white border-none  rounded-full'
            >
              View on map
            </a>
          </div>
        </div>
        <div className='basis-12 xl:basis-20 rounded-full grow-[2] shrink-[2]'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3626.7419368003393!2d73.70948899999999!3d24.632577800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967e56d4998a791%3A0xa774c7ef9d0148ae!2sEF%20Polymer%20Private%20Limited!5e0!3m2!1sen!2sin!4v1697694164293!5m2!1sen!2sin'
            className='w-full rounded-3xl h-[320px] sm:h-[420px] md:h-[450px] lg:h-full xl:h-[520px] 2xl:h-[580px]'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='map'
          ></iframe>
        </div>
      </div>
    </section>
  );
}
