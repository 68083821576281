import React from 'react';
import ourStoryCollage from '../../../assets/homepage/collage_homepage.webp';
import { ContentTile } from '../../../modules/atoms';

const statsData = [
  {
    num: '12000+',
    des1: 'farmers around the world are using our product',
  },
  {
    num: '160 t+',
    des1: 'of bio waste upcycled',
  },
  {
    num: '60+',
    des1: 'metric tons of water <br /> saved',
  },
];

const Stats = ({ num, des1 }) => {
  return (
    <div className='text-center h-auto grid'>
      <h2 className='font-bold text-white leading-none'>{num}</h2>
      <p
        className='font-ligh mt-2 max-w-[150px] xl:max-w-[250px] text-white'
        dangerouslySetInnerHTML={{ __html: des1 }}
      />
    </div>
  );
};

const OurStory = () => {
  return (
    <section className={`relative lg:pt-0 blade-pt lg:-mt-[10%] z-40 `}>
      <div
        id='our-story-board'
        className='container rounded-xl lg:bg-white lg:shadow-lg 2xl:p-20 xl:p-12 lg:p-12 md:p-0 z-[40] left-0 right-0'
      >
        <div className='flex lg:pb-20 lg:flex-row animate-box-fade-in flex-col-reverse gap-10 md:gap-12 lg:gap-10 xl:gap-24 2xl:gap-36 items-start md:items-center'>
          <div className='flex-1'>
            <img
              className='w-full h-full object-contain'
              src={ourStoryCollage}
              alt='Our story collage'
            />
          </div>
          <div className='flex-1'>
            <ContentTile
              label='Our Story'
              center={true}
              // className={window.innerWidth >= 1280 ? 'max-w-lg' : 'max-w-2xl'}
              className='max-w-2xl xl:max-w-2xl lg:!text-left lg:!pr-10 min-[1650px]:!pr-20'
              title='Challenging scarcity with sustainability'
              p1='How many times have you skimmed through a newspaper and found headlines describing the horrors of water scarcity in Indian agriculture? It is increasing, threatening, and alarming to millions, especially farmers.'
              p2='One such farmer’s child, Narayan Lal Gurjar viewed the 
                  frightening realities as an aspiration to invent a solution 
                  that can elevate sustainable conservation of water and 
                  agriculture. And he embarked on the journey to create 
                  the world’s first organic super-absorbent hydrogel! He 
                  soon created a hydrogel from eco-waste that could 
                  retain moisture, nourish the soil, and even boost crop 
                  growth naturally. This revolutionary hydrogel polymer 
                  transformed sustainable agriculture and is today known 
                  as EF Polymer.'
            />
          </div>
        </div>

        <div
          className='2xl:max-w-screen-xl xl:max-w-screen-lg max-w-[800px] lg:absolute w-[85%]
                    bg-greenDark  mt-10 md:mt-12 lg:mt-0 justify-around gap-y-4 md:gap-y-8 items-center md:items-start flex flex-col md:flex-row md:flex-wrap 
                    rounded-xl mx-auto top-full right-0 left-0 px-4 2xl:px-10 xl:px-5 2xl:py-10 py-7 lg:translate-y-[-50%]'
        >
          {statsData.map((stat, index) => {
            return (
              <React.Fragment key={index}>
                <div key={index} className='flex items-start'>
                  <Stats num={stat.num} des1={stat.des1} />
                </div>
                {index === statsData.length - 1 ? (
                  ''
                ) : (
                  <>
                    <span className='md:block hidden mt-2 h-24 w-[2px]  bg-[#227E39] rounded-3xl' />
                    <span className='block md:hidden mt-2 h-[1px] w-[50%] bg-[#227E39] rounded-3xl' />
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default OurStory;
