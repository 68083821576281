import ContentTile from '../../modules/atoms/contentTile';
import Farmer from '../../assets/agriculture/impact/farmer.webp';
import increase from '../../assets/agriculture/impact/increase.svg';
import lessUsage from '../../assets/agriculture/impact/lessusage.svg';
import reduced from '../../assets/agriculture/impact/reduced.svg';
import dashedCircle from '../../assets/agriculture/impact/dash_circles.svg';
import './index.css';
import React from 'react';

const data = [
  {
    id: 1,
    icon: lessUsage,
    percentage: 40,
    title: "Less usage <br class='hidden md:block'/> of water",
  },
  {
    id: 2,
    icon: reduced,
    percentage: 20,
    title: "Reduced usage <br class='hidden md:block' /> of fertilizers ",
  },
  {
    id: 3,
    icon: increase,
    percentage: '15',
    title: "Increase <br class='hidden md:block' /> in yield ",
  },
];

export const Impact = () => {
  return (
    <div className='relative blade-pt px-4 blade-pb flex flex-col items-center justify-center bg-brownLight '>
      <div className='absolute bottom-4 md:bottom-5 right-5'>
        <p className='text-xs opacity-60 font-plainThin'> *Terms & Conditions Apply</p>
      </div>

      <ContentTile
        title='Cultivate Smartly'
        center
        className='mx-auto max-w-sm sm:max-w-md md:max-w-2xl'
        p2='Growing a plant or a crop can be challenging, especially in arid regions. But with EF Polymer, you can retain water, reduce irrigation rounds and maximize the nourishment for your crops effortlessly.'
        label='The Impact'
      />
      <div className='relative mt-6 sm:mt-8 lg:mt-12 w-[250px] h-[250px] sm:w-[280px] sm:h-[280px] md:w-[350px] md:h-[350px] lg:w-[420px] lg:h-[420px] 2xl:w-[550px] 2xl:h-[550px] flex p-6 md:p-8 lg:p-10 justify-center items-center'>
        <div
          className='absolute animate-spin-slower inset-0 w-full h-full bg-contain'
          style={{
            background: `url(${dashedCircle})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
          }}
        />
        <img
          className='z-10 w-full h-[115%] -translate-y-1 md:-translate-y-2 2xl:-translate-y-5 object-cover'
          src={Farmer}
          alt='Farmer showing the front view of Fasal Amrit'
        />
      </div>
      <div className='mt-8 lg:mt-12 flex flex-col md:flex-row items-center justify-center gap-y-10 gap-x-6 md:gap-x-12'>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            {item.id === 2 && (
              <div className='impact-divider w-[80%] h-[2px] md:w-[2px] md:h-[100px]' />
            )}
            <div className='flex flex-col items-center'>
              <img src={item.icon} className='w-fit h-12 md:h-16 lg:h-20' alt={item.title} />

              <p className='text-greenDark text-center mt-2 font-bold text-2xl'>{`${item.percentage}%*`}</p>
              <p
                className='text-lg w-[200px] md:w-auto leading-6 text-center font-plainLight mt-1 opacity-70'
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </div>
            {item.id === 2 && (
              <div className='impact-divider w-[80%] h-[2px] md:w-[2px] md:h-[100px]' />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
