import React, { useEffect, useRef } from 'react';
import './header.css';
// import { IoMenu } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import MobileModal from './mobileModal';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const [showHeader, setShowHeader] = useState(true);
  const [isNavFixed, setIsNavFixed] = useState(false);

  let headerWrapper = useRef('');

  const handleModal = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  var oldScroll = window.scrollY;
  const handleScroll = () => {
    if (oldScroll > window.scrollY || window.scrollY < 10) {
      setShowHeader(true);
      if (window.scrollY > 100) {
        setIsNavFixed(true);
      } else setIsNavFixed(false);
    } else {
      setShowHeader(false);
      setIsNavFixed(false);
    }
    oldScroll = window.scrollY;
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <header className={`relative !z-[999]`}>
      <div
        ref={headerWrapper}
        className={`${isNavFixed ? 'shadow bg-white' : ' bg-transparent'} ${
          showHeader ? 'translate-y-0 ' : '-translate-y-full'
        } navbar-wrapper fixed top-0 left-0 right-0 transition-all duration-200`}
      >
        <div className='header_wrapper container'>
          <NavLink
            aria-label='Home'
            text='Home'
            className={`navLink ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
            to='/'
          >
            <span>Home</span>
          </NavLink>

          <NavLink
            aria-label='Technology'
            text='Technology'
            className={`navLink ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
            to='/technology'
          >
            <span className='tracking-wide'>Technology</span>
          </NavLink>

          <NavLink
            aria-label='Agriculture'
            text='Agriculture'
            className={`navLink ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
            to='/agriculture'
            end
          >
            <span className='tracking-wide'>Agriculture</span>
          </NavLink>

          <div className='logo'>
            <NavLink aria-label='Go to home' to='/'>
              <svg
                className='w-full h-full'
                viewBox='0 0 55 47'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M54.1661 20.7288C54.1091 20.422 53.9887 20.308 53.6666 20.3058C52.1411 20.3059 50.6222 20.5071 49.1492 20.9041C47.5411 21.3271 46.0032 21.9144 44.6821 22.9619C43.8277 23.6369 43.1748 24.4543 42.9295 25.5457C42.7761 26.225 42.8396 27.1805 43.0719 27.575C43.8671 26.0081 45.2627 24.6691 46.0536 24.6559C44.9582 26.3324 43.7817 28.0089 42.7345 29.7621C41.6873 31.5153 41.076 33.4986 40.4166 35.4468C40.3388 35.3386 40.2803 35.2177 40.2435 35.0896C39.9346 34.1407 39.6279 33.1918 39.3168 32.2407C38.466 29.5039 36.7731 27.1056 34.4795 25.3879C33.2286 24.4521 32.0083 23.4769 30.7705 22.5236C30.6887 22.4753 30.6001 22.4399 30.5076 22.4184L30.6412 22.2694C31.9842 22.7954 33.3797 23.2139 34.5255 24.2417C32.613 18.7302 26.4042 16.5715 22.0598 18.9953C22.0556 19.02 22.0556 19.0452 22.0598 19.0698C22.7477 20.8537 23.5255 22.5937 24.7217 24.1102C25.2546 24.8191 25.924 25.4141 26.6905 25.8602C27.4569 26.3062 28.3049 26.5942 29.1843 26.7072C30.7924 26.9263 32.3807 26.751 33.9559 26.4332C34.0575 26.3985 34.1675 26.3975 34.2697 26.4303C34.3719 26.463 34.4608 26.5278 34.5233 26.6151C35.0535 27.3054 35.6385 27.9585 36.1051 28.6883C37.4546 30.803 38.1404 33.172 38.6333 35.6068C38.6514 35.7576 38.638 35.9106 38.5939 36.056C37.3144 41.2038 32.8386 45.145 27.5872 45.7805V46.5401H38.9597C39.7155 46.5401 39.8667 46.413 39.9894 45.6635C40.3312 43.6188 40.6466 41.5742 41.0125 39.5273C41.4638 36.9436 41.9085 34.3532 42.7038 31.8571C42.9979 31.0414 43.3351 30.2419 43.7138 29.4619C43.8343 29.1901 44.0073 29.1244 44.3228 29.2602C45.173 29.6279 46.1013 29.7785 47.0241 29.6985C49.2434 29.5123 51.0267 28.4823 52.431 26.808C53.9098 25.0679 54.5977 23.054 54.1661 20.7288Z'
                  fill='#8C6438'
                />
                <path
                  d='M21.8429 45.7644H19.1022C19.0036 45.7644 18.9072 45.7754 18.7999 45.7644L18.7999 0.789528H19.295C21.5866 0.789528 23.8804 0.750095 26.172 0.804865C27.2065 0.8075 28.2368 0.938488 29.2391 1.19483C34.4839 2.67143 37.6934 6.10222 38.8173 11.439C38.9344 12.1826 39.0075 12.9324 39.0364 13.6846C39.0583 13.9277 39.1525 14.0767 39.4044 14.0811C39.6564 14.0855 39.7703 13.9343 39.7747 13.6933C39.7747 13.6035 39.7747 13.5137 39.7747 13.4239V0.717235C39.7747 0.0753308 39.7396 0.0380859 39.1174 0.0380859H1.3041C1.04777 0.0380859 0.865937 0.143247 0.881273 0.417096C0.896609 0.690946 1.06968 0.791721 1.31943 0.791721C1.56919 0.791721 1.80579 0.791721 2.04897 0.791721C2.68301 0.776796 3.2986 1.00578 3.76876 1.43144C4.48296 2.05143 4.68451 2.86641 4.68451 3.77778C4.68451 16.7765 4.68451 29.7701 4.68451 42.7587C4.68451 42.912 4.68451 43.0676 4.68451 43.2209C4.67111 43.8214 4.44905 44.3985 4.05647 44.853C3.66388 45.3076 3.12527 45.6112 2.53314 45.7118C2.12127 45.7885 1.68749 45.7316 1.27342 45.7929C1.19078 45.8167 1.11505 45.86 1.05254 45.9191C0.990038 45.9781 0.942582 46.0513 0.914134 46.1325C0.857173 46.3516 1.01929 46.5093 1.27342 46.5115H1.5407C7.62384 46.5217 13.7085 46.5312 19.7945 46.54H21.8517L21.8429 45.7644Z'
                  fill='#499F0F'
                />
                <rect x='18.8011' y='45.7686' width='9.30645' height='0.771691' fill='#8C6438' />
                <rect x='17.4535' y='43.9829' width='1.34763' height='2.55736' fill='#499F0F' />
              </svg>
            </NavLink>
          </div>

          <NavLink
            aria-label='About us'
            text='About us'
            className={`navLink ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
            to='/about-us'
          >
            <span className='tracking-wide'>About Us</span>
          </NavLink>

          <NavLink
            aria-label='Resources'
            text='Resources'
            className={`navLink ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
            to='/resources'
          >
            <span className='tracking-wide'>Resources</span>
          </NavLink>

          <NavLink
            aria-label='Contact us'
            text='Contact us'
            className={`navLink ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
            to='/contact-us'
          >
            <span className='tracking-wide'>Contact Us</span>
          </NavLink>

          <button className='burger-icon' aria-label='Menu' onClick={handleModal}>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0'
              viewBox='0 0 512 512'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill='none'
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeWidth='48'
                d='M88 152h336M88 256h336M88 360h336'
              ></path>
            </svg>
          </button>

          <MobileModal
            className={`${isOpen ? 'showModal' : 'hideModal'} `}
            onClick={handleModal}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
        {/* <div className="hr_gradient"></div> */}
      </div>
    </header>
  );
}
