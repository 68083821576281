import { ContentTile } from '../../modules/atoms';
import Polymer from '../../assets/technology/polymer.jpg';

export const IntroducePolymer = () => {
  return (
    <div className='blade-pt blade-pb bg-brownMedium'>
      <div className='container'>
        <ContentTile
          label='Introducing EF Polymer'
          className='max-w-2xl mx-auto px-2 sm:px-0'
          center
          title='The Eco-Friendly Polymer'
          p2='EF Polymer is a revolutionary organic Super Absorbent Polymer that can hold up to 100 times its weight in water. It is made from 100% organic materials, making it fully biodegradable, compostable, and non-toxic. This makes our product a much more sustainable alternative to traditional SAPs, which are often made from petroleum-based products.'
        />
        <div className='flex items-center justify-center mt-8 md:mt-12 '>
          <div className='w-full md:w-8/12 bg-white p-[6px] rounded-3xl'>
            <img
              src={Polymer}
              className='rounded-[20px] object-cover w-full h-full'
              alt='SAP (Super Absorbent Polymer) that is made entirely from organic waste'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
