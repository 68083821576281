import ContentTitle from "../../modules/atoms/contentTile";
import { useRef, useState } from "react";
import Mid2017 from "../../assets/about/growth/logo-1.webp";
import Dec2018 from "../../assets/about/growth/logo-2.webp";
import Oct2019 from "../../assets/about/growth/logo-3.webp";
import June2020 from "../../assets/about/growth/logo-4.webp";
import Nov2021 from "../../assets/about/growth/logo-5.webp";
import March2022 from "../../assets/about/growth/logo-6.webp";
import April2023 from "../../assets/about/growth/logo-7.webp";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./index.css";
import "swiper/css";

const data = [
  {
    id: 1,
    heading: "Conceived idea and developed the prototype.",
    image: Mid2017,
    year: "2017",
  },
  {
    id: 2,
    heading:
      "Incorporated in India and valitated the prototype in Maize in collaboration with MPUAT.",
    image: Dec2018,
    year: "2018",
  },
  {
    id: 3,
    heading:
      "Selected for OIST Innovation Accelerator in Japan and Final product developed.",
    image: Oct2019,
    year: "2019",
  },
  {
    id: 4,
    heading:
      "Incorporated in Japan and conducted the first large-scale pilot in India in 80 Ha.",
    image: June2020,
    year: "2020",
  },
  {
    id: 5,
    heading: `Seed funding from the Japanese VC called MTG Venture and Beyond Next Venture. Product became available across 10+ 
      districts in Uttar Pradesh in retail stores and over E-comm chanels like Amazon, Flipkart, Bighaat, etc.`,
    image: Nov2021,
    year: "2021",
  },
  {
    id: 6,
    heading: `Product became available across 15+ states of Japan in collaboration with Japanese Agriculture (JA). 
    The Pilot begins in California and Kanas in collaboration with Wismettac Food inc. in the US.`,
    image: March2022,
    year: "2022",
  },
  {
    id: 7,
    heading: `Fully commercialized stage Series-A funding Japanese VCs (Wismettac, BNV, MTG, etc.) Product became available in 16 
     states of US.`,
    image: April2023,
    year: "2023",
  },
];

const NavigationButtons = ({ onClickPrev, onClickNext }) => {
  const swiper = useSwiper();

  const handlePrevClick = () => {
    if (onClickPrev) {
      onClickPrev();
    } else {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (onClickNext) {
      onClickNext();
    } else {
      swiper.slideNext();
    }
  };

  return (
    <div className="flex mt-4 sm:mt-6 justify-center lg:justify-start gap-x-6">
      <button
        aria-label="Move to previous slide"
        onClick={handlePrevClick}
        className="border outline-green outline-offset-0 hover:bg-yellow hover:stroke-white border-yellow p-2 stroke-yellow rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="inherit"
          className="w-5 md:w-8 h-5 md:h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </button>
      <button
        aria-label="Move to next slide"
        onClick={handleNextClick}
        className="border outline-green outline-offset-0 hover:bg-yellow hover:stroke-white border-yellow stroke-yellow p-2 rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="inherit"
          className="w-5 md:w-8 h-5 md:h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </div>
  );
};

const MobileView = () => {
  return (
    <div className="lg:hidden">
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        autoplay
        loop={true}
        className="!h-fit mt-6"
        autoHeight
      >
        {data.map((item, index) => (
          <SwiperSlide
            key={index}
            className="!h-auto !flex !flex-col !items-center"
          >
            <div
              style={{ border: "2px solid rgba(215, 215, 215, 0.33)" }}
              className="relative w-[280px] sm:w-[380px] flex items-center justify-center rounded-xl h-[280px] sm:h-[380px] 
              bg-[#0a4f2c] overflow-hidden after:content-[''] 
              after:bg-[url(assets/about/growth/orange-grad.svg)] after:bg-cover after:bg-center after:w-full 
              after:h-full after:bgred-200 after:absolute"
            >
              <img
                className={`h-36 md:h-44 ${index === 5 ? "pl-3" : ""}`}
                src={item.image}
                alt={"Achievements in " + item.year}
              />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h1 className="font-plainSemibold text-white text-opacity-30 mt-6 text-[32px]">
                {item.year}
              </h1>
              <p className="text-white text-center font-plainLight max-w-md mt-4">
                {item.heading}
              </p>
            </div>
          </SwiperSlide>
        ))}
        <NavigationButtons />
      </Swiper>
    </div>
  );
};

export const Growth = () => {
  const [selected, setSelected] = useState(1);

  const onClickNext = () => {
    if (selected === data.length) {
      return;
    }
    setSelected((prev) => prev + 1);
  };

  const onClickPrev = () => {
    if (selected === 1) {
      return;
    }
    setSelected((prev) => prev - 1);
  };

  const imageRef = useRef(null);
  const nodeRef = useRef(null);

  return (
    <div className="blade-pt-sm blade-pb bg-greenDark">
      <div className="container-sm">
        <ContentTitle
          themeDark
          className="mx-auto max-w-xl px-2 sm:px-0"
          center
          title="Driving towards growth"
          p2="From humble beginnings to revolutionizing agriculture in 9 countries, EF Polymer continues to make a sustainable impact."
        />
        <div className="mt-14 2xl:mt-20 hidden lg:flex gap-x-6 lg:gap-x-16 2xl:gap-x-20 justify-center">
          {/* Image Slider  */}
          <div
            ref={imageRef}
            className="w-[320px] 2xl:w-[480px] overflow-hidden"
          >
            <div
              style={{
                transform: `translateX(-${
                  (selected - 1) * (imageRef.current?.offsetWidth + 24)
                }px)`,
              }}
              className={`w-fit transition-all duration-500 ease-in-out flex gap-x-6`}
            >
              {data.map((item, index) => (
                <div
                  key={index}
                  style={{ border: "2px solid rgba(215, 215, 215, 0.33)" }}
                  className="relative overflow-hidden w-[320px] 2xl:w-[480px] flex items-center justify-center 
                  rounded-3xl bg-[#0a4f2c] h-[320px] 2xl:h-[480px] shadow-[8px_8px_12px_0px_rgba(0, 0, 0, 0.20)] 
                  after-content-[''] after:bg-[url(assets/about/growth/orange-grad.svg)] after:bg-cover after:bg-center 
                  after:w-full after:h-full after:bgred-200 after:absolute"
                >
                  <img
                    className={`lg:h-40 2xl:h-56 ${index === 5 ? "pl-5" : ""}`}
                    src={item.image}
                    alt={"Achievements in " + item.year}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Text Animation  */}

          <div className="w-[40%] overflow-hidden self-stretch flex flex-col justify-between">
            <SwitchTransition>
              <CSSTransition
                in={true}
                key={selected}
                unmountOnExit
                mountOnEnter
                timeout={300}
                classNames="growth-text"
                nodeRef={nodeRef}
              >
                <div className="py-4" ref={nodeRef}>
                  <h1
                    className="font-plainSemibold leading-[46px] text-white text-opacity-30 text-[48px] 
                    2xl:text-[52px]"
                  >
                    {data[selected - 1].year}
                  </h1>
                  <p
                    className="text-white text-opacity-90 text-base lg:text-xl 2xl:text-2xl font-plainLight 
                  mt-6 2xl:mt-8 leading-relaxed"
                  >
                    {data[selected - 1].heading}
                  </p>
                </div>
              </CSSTransition>
            </SwitchTransition>
            <div className="hidden lg:block">
              <NavigationButtons
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
              />
            </div>
          </div>
        </div>

        {/* Timeline progress bar  */}
        <div className="hidden lg:flex justify-center mt-10 lg:mt-12 2xl:mt-16">
          {data.map((item, i) => (
            <div key={i}>
              <p
                className={`${
                  item.id <= selected ? "opacity-100" : "opacity-50"
                } text-base font-plainNormal text-white text-center pb-3 `}
              >
                {item.year}
              </p>
              <div className="flex items-center mt-1">
                <div
                  className={`w-4 h-4 bg-yellow rounded-full transition-all duration-300 ease-in-out ${
                    i === 0 ? "block" : "hidden"
                  }`}
                />
                <div
                  className={` w-[78px] lg:w-[100px] 2xl:w-[160px] h-[2.2px] bg-white bg-opacity-40`}
                >
                  <div
                    className={`${
                      selected >= item.id
                        ? "w-[78px] lg:w-[100px] 2xl:w-[160px]"
                        : "w-0"
                    } transition-all duration-500 ease-in-out h-[2.2px] bg-yellow`}
                  />
                </div>
                <div
                  className={`w-4 h-4 rounded-full transition-all duration-300 ease-in-out ${
                    selected >= item.id
                      ? "bg-yellow delay-300"
                      : "bg-white bg-opacity-40 delay-300"
                  }`}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Mobile View Swiper */}
        <MobileView />
      </div>
    </div>
  );
};
