import { ContentTile } from '../../modules/atoms';
import { Swiper, SwiperSlide } from 'swiper/react';
import CostEffective from '../../assets/technology/greenChoice/Cost-effective.png';
import WaterRetention from '../../assets/technology/greenChoice/High water.png';
import SustainableManufacturing from '../../assets/technology/greenChoice/Sustainable.png';
import './index.css';
import 'swiper/css';
import { Pagination } from 'swiper';

const data = [
  {
    id: 1,
    heading: 'Sustainable manufacturing',
    para: 'Our SAP is produced through an eco-conscious manufacturing process that minimizes its environmental impact. Compared to industry standards, our approach consumes less energy, leading to significantly reduced CO2 emissions. Additionally, we prioritize responsible water usage and waste reduction to ensure a cleaner production process.',
    image: SustainableManufacturing,
  },
  {
    id: 2,
    heading: 'High water retention capacity',
    para: 'Our SAP boasts remarkable water retention capabilities, making it suitable for a wide range of applications. In agriculture, it offers a retention capacity of approximately 50 times its weight, perfectly tailored to the needs of crops and soil moisture management.',
    image: WaterRetention,
  },
  {
    id: 3,
    heading: 'Cost-effective at scale',
    para: 'We understand the importance of cost-effectiveness, particularly in large-scale production. Our technology achieves cost competitiveness by utilizing innovative methods. This involves the collection of bio-waste materials such as orange peels and incorporating them into the production of our 100% organic polymer. This helps reduce our reliance on traditional industrial methods and embrace more natural and sustainable approaches.',
    image: CostEffective,
  },
];

const Card = ({ heading, para, image }) => {
  return (
    <div className='xl:w-auto rounded-[10px] h-full lg:h-auto bg-brownMedium'>
      <div className='w-full h-[300px] md:h-[400px] lg:h-[300px] rounded-[150px]'>
        <img
          src={image}
          alt={heading}
          className='w-full h-full rounded-t-[10px] object-cover object-center'
        />
      </div>
      <div className='flex items-start gap-x-2 px-4 md:px-8 lg:px-4 2xl:px-8 py-8'>
        <span className='w-[100px] fill-greenDark'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 86 68'
            fill='inherit'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M85.5999 6.30916C85.5999 5.45153 85.2756 5.45153 84.3029 5.45153C80.0877 5.45153 76.1968 6.02328 72.3059 6.8809C68.0908 7.73853 63.8756 9.1679 60.309 11.7408C58.0393 13.456 56.4181 15.1713 55.7696 17.7441C55.4453 19.4594 55.4453 21.4605 56.0938 22.604C58.3635 18.8876 61.9302 15.743 64.1999 15.743C61.2817 19.7453 58.0393 23.7475 55.4454 27.7498C52.5272 32.0379 50.906 36.6119 49.2848 41.1859C48.9605 40.9 48.9605 40.6141 48.9605 40.3283C47.9878 38.0413 47.3393 35.7543 46.3666 33.7531C44.0969 27.178 39.8817 21.7464 33.3969 17.7441C30.1545 15.4571 26.9121 13.1702 23.3454 10.8832C23.0212 10.8832 23.0212 10.5973 22.6969 10.5973L23.0212 10.3114C26.5878 11.4549 30.4787 12.5984 33.3969 14.8854C28.209 2.02104 11.6727 -3.12471 0 2.59279C0 2.59279 0 2.59278 0 2.87866C1.94545 7.16678 3.89089 11.169 7.13331 14.8854C10.0515 18.3159 14.2666 20.317 19.1303 20.8888C23.3454 21.4605 27.5605 20.8888 31.7757 20.317C32.4242 20.317 32.7484 20.317 33.3969 20.8888C34.6939 22.604 36.3151 24.0334 37.6121 25.7486C41.1787 30.6085 43.1242 36.326 44.4211 42.0435C44.4211 42.3294 44.4211 42.9011 44.4211 43.187C40.8545 55.1937 29.1818 64.3417 14.9151 65.7711V67.7722C24.9666 67.7722 35.3424 67.7722 45.3939 67.7722C47.3393 67.7722 47.6635 67.4864 47.9878 65.7711C48.9605 60.9112 49.609 56.0514 50.5817 51.1915C51.8787 45.1881 52.8514 38.8989 55.1211 33.1814C55.7696 31.1803 56.7423 29.465 57.715 27.4639C58.0393 26.8921 58.3635 26.6063 59.3362 26.8921C61.6059 27.7498 64.1999 28.0356 66.4696 28.0356C72.3059 27.4639 77.1696 25.1769 81.0605 21.1746C84.9514 16.3148 86.8968 11.7408 85.5999 6.30916Z'
              fill='inherit'
            />
          </svg>
        </span>
        <div>
          <h5 className='font-plainSemibold text-greenDark'>{heading}</h5>
          <p className='text-base font-normal mt-2 opacity-70'>{para}</p>
        </div>
      </div>
    </div>
  );
};

export const GreenChoice = () => {
  return (
    <div className='blade-pt bg-brownLight'>
      <div className='container'>
        <ContentTile
          center
          className='mx-auto max-w-2xl px-2 sm:px-0'
          label='The Green Choice'
          title='Revolutionizing sustainability with our 100% organic SAP'
          p2="Our Super Absorbent Polymer is an organic and biodegradable solution. Instead of being incinerated, we repurpose crop residues to create this innovative product. What sets us apart is our commitment to a chemical-free manufacturing process. By choosing us, you're not just making a better choice for your needs; you're also making a positive impact on the planet."
        />
        <h3 className='font-plainSemibold mt-8 md:mt-14 xl:text-[32px] 2xl:text-[36px] text-[28px] leading-tight text-center text-greenDark'>
          What makes us unique?
        </h3>
        <div className='mt-10 hidden xl:grid grid-cols-3 gap-6 justify-center'>
          {data.map((item, index) => (
            <Card key={index} heading={item.heading} para={item.para} image={item.image} />
          ))}
        </div>

        {/* Mobile View */}
        <div className='xl:hidden mt-6 md:mt-10 lg:mt-10'>
          <Swiper
            spaceBetween={30}
            autoHeight={false}
            modules={Pagination}
            pagination={true}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 1.3,
              },
              768: {
                slidesPerView: 1.5,
              },
              800: {
                slidesPerView: 2.1,
              },
              1024: {
                slidesPerView: 2.2,
              },
              1230: {
                slidesPerView: 2.5,
              },
            }}
            loop={true}
            autoplay
            className='!h-fit !pb-6'
          >
            {data.map((item, index) => (
              <SwiperSlide key={index} className='!h-auto !rounded-[10px] bg-brownMedium'>
                <Card heading={item.heading} image={item.image} para={item.para} />
              </SwiperSlide>
            ))}
            <span className='swiper-pagination'></span>
          </Swiper>
        </div>
      </div>
    </div>
  );
};
