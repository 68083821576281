import { ContentTile } from '../../modules/atoms';
import VerticalLine from '../../assets/technology/endless-possibilities-line.svg';
import LeftVerticalLine from '../../assets/technology/endless-possibilities-line_left.svg';
import RightVerticalLine from '../../assets/technology/endless-possibilities-line_right.svg';
import './index.css';

const data = [
  {
    id: 1,
    box: [
      { id: 1, tag: 'Soil Conditioner' },
      { id: 2, tag: 'Seedling' },
      { id: 3, tag: 'Seed Coating' },
      { id: 4, tag: 'Horticulture' },
      { id: 5, tag: 'Floriculture' },
      { id: 6, tag: 'Tree Seedling' },
    ],
  },

  {
    id: 2,
    box: [
      { id: 7, tag: 'Plantation' },
      { id: 8, tag: 'Forestry' },
      { id: 9, tag: 'Road Side Plantation' },
      { id: 10, tag: 'Hydroponics' },
      { id: 11, tag: 'Nursery Bed' },
      { id: 12, tag: 'Nursery Plant' },
    ],
  },
  {
    id: 3,
    box: [
      { id: 13, tag: 'Nursery Potting Mixture' },
      { id: 14, tag: 'Home Plant' },
      { id: 15, tag: 'Gardening' },
      { id: 16, tag: 'Rooftop Gardening' },
      { id: 17, tag: 'Wall Gardening' },
      { id: 18, tag: 'Hotel Resort' },
    ],
  },
  {
    id: 4,
    box: [
      { id: 19, tag: 'Concrete Curing' },
      { id: 20, tag: 'Prevention of Condensation' },
      { id: 21, tag: 'Water Sealent' },
      { id: 22, tag: 'Sewage Sludge' },
      { id: 23, tag: 'Sandbags' },
      { id: 24, tag: 'Water Resistant tape' },
    ],
  },
  {
    id: 5,
    box: [
      { id: 25, tag: 'Battery Gel' },
      { id: 26, tag: 'Diapers' },
      { id: 27, tag: 'Napkins' },
      { id: 28, tag: 'Emergency Toilets' },
      { id: 29, tag: 'Ice Packs' },
      { id: 30, tag: 'Dental Gel' },
    ],
  },
  {
    id: 6,
    box: [
      { id: 31, tag: 'Pet Sheets' },
      { id: 32, tag: 'Cat Sand' },
      { id: 33, tag: 'Contact Lens' },
      { id: 34, tag: 'Cosmetics' },
      { id: 35, tag: 'Bacteria Removing Gel' },
      { id: 36, tag: 'Wound Bandage' },
    ],
  },
];

const MobileView = () => {
  return (
    <>
      <div className='bg-transparent w-fit overflow-hidden mt-8 lg:hidden flex gap-x-6 items-center marquee'>
        {data.map((item) => {
          if (item.id <= 2) {
            return item.box.map((item, index) => (
              <div
                key={index}
                className='border px-3 w-max py-4 border-white border-opacity-60 rounded-md'
              >
                <p className='text-white w-full text-center font-normal text-base sm:text-lg'>
                  {item.tag}
                </p>
              </div>
            ));
          }
          return null;
        })}
        {/* For inifinite loop */}
        {data.map((item) => {
          if (item.id <= 2) {
            return item.box.map((item, index) => (
              <div
                key={index}
                className='border px-3 w-max py-4 border-white border-opacity-60 rounded-md'
              >
                <p className='text-white w-full text-center font-normal text-base sm:text-lg'>
                  {item.tag}
                </p>
              </div>
            ));
          }
          return null;
        })}
      </div>
      <div className='bg-transparent w-fit overflow-hidden mt-4 flex lg:hidden gap-x-6 items-center marquee-reverse'>
        {data.map((item) => {
          if (item.id >= 3 && item.id <= 4) {
            return item.box.map((item, index) => (
              <div
                key={index}
                className='border w-max px-3 py-4 border-white border-opacity-60 rounded-md'
              >
                <p className='text-white w-full text-center font-normal text-base sm:text-lg'>
                  {item.tag}
                </p>
              </div>
            ));
          }
          return null;
        })}
        {/* For inifinite loop */}
        {data.map((item) => {
          if (item.id >= 3 && item.id <= 4) {
            return item.box.map((item, index) => (
              <div
                key={index}
                className='border w-max px-3 py-4 border-white border-opacity-60 rounded-md'
              >
                <p className='text-white w-full text-center font-normal text-base sm:text-lg'>
                  {item.tag}
                </p>
              </div>
            ));
          }
          return null;
        })}
      </div>

      <div className='bg-transparent w-fit overflow-hidden mt-4 flex lg:hidden gap-x-6 items-center marquee'>
        {data.map((item) => {
          if (item.id >= 5 && item.id <= 6) {
            return item.box.map((item, index) => (
              <div
                key={index}
                className='border w-max px-3 py-4 border-white border-opacity-60 rounded-md'
              >
                <p className='text-white w-full text-center font-normal text-base sm:text-lg'>
                  {item.tag}
                </p>
              </div>
            ));
          }
          return null;
        })}
        {/* For inifite loop */}
        {data.map((item) => {
          if (item.id >= 5 && item.id <= 6) {
            return item.box.map((item, index) => (
              <div
                key={index}
                className='border w-max px-3 py-4 border-white border-opacity-60 rounded-md'
              >
                <p className='text-white w-full text-center font-normal text-base sm:text-lg'>
                  {item.tag}
                </p>
              </div>
            ));
          }
          return null;
        })}
      </div>
    </>
  );
};

export const Possibilities = () => {
  return (
    <div className='bg-greenDark overflow-hidden blade-pt blade-pb'>
      <div className='container'>
        <ContentTile
          center
          themeDark
          className='mx-auto max-w-2xl'
          label='Endless Possibilities'
          title='It’s time to make the shift!'
          p2='Let’s make the shift and bring a positive contribution to our environment and grow together.  Can EF Polymer help your industry go sustainable?'
        />
        <h6 className='text-center font-plainNormal text-white animate-text-fade-in md:text-lg text-[14px]'>
          Let’s discover together!
        </h6>

        <div className='mt-16 hidden lg:flex flex-row items-center justify-center'>
          {data.map((item) => (
            <div key={item.id} className='flex items-center'>
              <img
                className=''
                aria-hidden
                alt='vertical line with leaves'
                src={item.id === 1 ? LeftVerticalLine : VerticalLine}
              />
              <div className='justify-between'>
                {item.box.map((item, index) => (
                  <div
                    key={index}
                    className={`flex items-center px-2 xl:px-4 2xl:px-8 w-[120px] xl:w-[168px] 2xl:w-[200px] h-[101px] justify-center border-t border-[#4A9E12] ${
                      item.id % 6 === 0 && 'border-b'
                    }`}
                  >
                    <p className='text-white text-center text-[14px] xl:text-base font-normal'>
                      {item.tag}
                    </p>
                  </div>
                ))}
              </div>
              {item.id === data.length && (
                <img aria-hidden alt='vertical line with leaves' src={RightVerticalLine} />
              )}
            </div>
          ))}
        </div>

        {/* Mobile View  */}
        <MobileView />
      </div>
    </div>
  );
};
