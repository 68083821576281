import IcepackImg from '../../assets/technology/icepack.jpg';
// import Logo from "../../assets/globals/efp_logo.svg";
import { ContentTile } from '../../modules/atoms';
import { useState } from 'react';

export const IcePack = () => {
  const [state, setState] = useState(false);
  return (
    <div className='blade-pt blade-pb bg-brownLight'>
      <div className='container flex lg:flex-row flex-col-reverse flex-wrap 2xl:gap-24 lg:gap-10 gap-5'>
        <div className='relative flex-1 rounded-2xl p-1 bg-gradient-to-tl from-green via-greenDark to-green h-fit min-w-[300px] max-w-2xl mx-auto'>
          <div className='rounded-xl overflow-hidden'>
            <img
              src={IcepackImg}
              className='h-full w-full object-cover'
              alt='Cy-cool ice pack from front'
            />
          </div>
          {/* <div
            className="bg-brownLight absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] rounded-[18px] 
            md:rounded-[40px] z-10 px-[28px] sm:px-[36px] md:px-[52px] lg:px-[68px] xl:px-[70px] py-[30px] sm:py-[40px] md:py-[58px] lg:py-[75px] 
            xl:py-[78px]"
          >
            <img
              className="w-[60px] sm:w-[95px] md:w-28 lg:w-32 xl:w-40"
              src={Logo}
              alt="EPF logo"
            />
          </div> */}
        </div>

        <div className='max-w-3xl -auto flex-1 my-auto min-w-[300px] mx-auto lg:mx-0'>
          <ContentTile
            label='Innovating Sustainability with Cy-cool™'
            title='Your go-to eco-conscious'
            className='!p-0 !gap-y-1'
          />

          <h2 id='text' className='text-greenDark font-bold tracking-tight leading-none pb-4'>
            ice packs
          </h2>

          <div className='max-w-2xl'>
            <p className='md:text-lg font-normal mt-1 max-w-3xl text-[14px]'>
              Every year, over 1 billion small-sized refrigerants are used for food preservation in
              Japan, but their reliance on petroleum-based super absorbent polymer (SAP) poses
              environmental challenges. 
            </p>

            <p
              className={`${
                state ? 'h-fit' : 'h-0'
              } md:text-lg font-normal mt-2 max-w-3xl text-[14px] lg:h-fit overflow-hidden`}
            >
              EF Polymer, in partnership with Iwatani Corporation and Iwatani Materials Corporation,
              presents Cy-cool™, a revolutionary biodegradable ice pack crafted entirely from
              natural materials like orange peels. This innovative product not only cools but can be
              as a soil water retention agent after being used as a cold pack. Its goal is to boost
              environmental sustainability and reduce the ecological footprint of refrigerants.
            </p>
            <button
              aria-label='Read more'
              onClick={() => {
                state ? setState(false) : setState(true);
              }}
              className='text-green lg:hidden flex text-sm mt-1'
            >
              {!state ? 'Read More' : 'Read Less'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
