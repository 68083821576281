import React from 'react';
import { ContentTile } from '../../modules/atoms';
import Logo1 from '../../assets/homepage/featured-in/ForbesJapan_logo.svg';
import Logo2 from '../../assets/homepage/featured-in/Nikkei_Logo.svg';
import Logo3 from '../../assets/homepage/featured-in/TVTokyo_logo.svg';
import Logo4 from '../../assets/homepage/featured-in/newspicks_logo.svg';
import Logo5 from '../../assets/homepage/featured-in/japantimes_logo.svg';

export const FeaturedIn = () => {
  return (
    <section className=''>
      <div className='blade-pt blade-pb flex flex-col items-center bg-[#F1EDE6]'>
        <ContentTile title='Featured in' />
        <div className='flex px-4 flex-row flex-wrap items-center justify-center mt-8 gap-y-8 gap-x-16 lg:gap-x-24 xl:gap-x-28'>
          <img className='w-28' src={Logo1} alt='Japan Forbes' />
          <img className='w-40' src={Logo5} alt='The Japan Times' />
          <img className='w-28' src={Logo3} alt='TV Tokyo' />
          <img className='w-24' src={Logo2} alt='NIKKEI' />
          <img className='w-20' src={Logo4} alt='News Picks' />
        </div>
      </div>
    </section>
  );
};
