import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "../../modules/atoms";
import "./error.css";
import { Header, Footer } from "../../modules/core";

export default function Error404Screen() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="errorScreenWrapper">
        <h1 className="text-green font-bold">Coming soon </h1>
        <NavLink
          to="/"
          className="p-2 px-5 mt-12 m-6 br-2 rounded-md border-green border-2 text-green"
        >
          Go to Homepage
        </NavLink>
      </div>
    </>
  );
}
