import Viewer from 'viewerjs-react';
import awardIcon from '../../assets/resources/awards icon.svg';

export const AwardCard = ({ url, name, className }) => {
  return (
    <div
      className={`${className} rounded-lg bg-white p-2 lg:p-4 pb-4 lg:pb-6 flex flex-col shadow-lg`}
    >
      <Viewer>
        <div className='rounded-lg cursor-zoom-in h-auto min-h-[150px]'>
          <img src={url} alt={name} className='w-full rounded-lg h-full object-contain img' />
        </div>
      </Viewer>

      <span className='mt-2 w-[40px] h-[40px] md:w-[60px] md:h-[60px]'>
        <img className='w-full h-full' src={awardIcon} alt='' />
      </span>

      <p className='font-plainNormal text-lg mt-2'>{name}</p>
    </div>
  );
};

export const CertificateCard = ({ url, name, id, className }) => {
  return (
    <div className={`${className} rounded-lg bg-white p-2 lg:p-4 flex flex-col shadow-lg`}>
      <Viewer className=' h-full'>
        <div className='rounded-lg cursor-zoom-in h-full min-h-[150px] flex '>
          <img
            src={url}
            alt={name}
            className='w-full rounded-lg h-full self-stretch object-cover'
          />
        </div>
      </Viewer>

      {/* <span className='mt-2 w-[40px] h-[40px] md:w-[60px] md:h-[60px]'>
        <img className='w-full h-full' src={awardIcon} alt='' />
      </span>

      <p className='font-plainNormal text-lg mt-2'>{name}</p> */}
    </div>
  );
};
