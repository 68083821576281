// import { useMemo } from 'react';

export const CaseStudyCard = ({ imgSrc, label, title, des, className }) => {
  // const truncatedDes = useMemo(() => {
  //   if (window.innerWidth >= 1280) {
  //     return des.length > 200 ? `${des.substring(0, 200)}...` : des;
  //   } else {
  //     return des.length > 120 ? `${des.substring(0, 120)}...` : des;
  //   }
  // }, [des]);

  return (
    <div
      className={`${className} rounded-lg bg-white shadow-lg p-2 lg:p-4 pb-6 lg:pb-12 flex flex-col`}
    >
      <div className='rounded-lg h-[280px]'>
        <img className='w-full rounded-lg h-full object-cover' src={imgSrc} alt={title} />
      </div>

      <div className='mt-6 h-auto flex-1 px-2 lg:px-0'>
        <div className='h-full flex flex-col justify-between'>
          <div>
            <label className='uppercase underline text-sm font-plainNormal underline-offset-4 text-gray mt-12'>
              {label}
            </label>
            <h4 className='text-greenDark 2xl:mt-6 mt-3 font-bold'>{title}</h4>
            <p className='lg:pr-10 font-plainNormal 2xl:text-lg lg:text-base text-sm mt-2'>{des}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
