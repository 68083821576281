import React from 'react';
import Viewer from 'viewerjs-react';

export const PressCard = ({ imgSrc, className, id }) => {
  return (
    <div className={`${className} rounded-lg bg-white p-2 lg:p-4 flex flex-col shadow-lg`}>
      <Viewer>
        <div className='rounded-lg hover:cursor-zoom-in focus:cursor-zoom-in h-auto min-h-[120px]'>
          <img
            className='w-full rounded-lg h-full object-contain object-top img'
            src={imgSrc}
            alt=''
          />
        </div>
      </Viewer>
    </div>
  );
};
