import React, { useEffect, useState } from 'react';
import lineOverlay from '../../../assets/globals/line-overlay.webp';
import { SwitchTransition, Transition } from 'react-transition-group';
import vdo from '../../../assets/homepage/video_homepage.mp4';
import poster from '../../../assets/homepage/poster.webp';
import { gsap } from 'gsap';

import './index.css';

export default function Banner() {
  const [activeTextInd, setActiveTextInd] = useState(0);
  const list = [
    { text: 'Sustainable Agriculture' },
    { text: 'Organic Farming' },
    { text: 'Water Conservation' },
    { text: 'Agri-waste Management' },
    { text: 'Soil Health' },
    { text: 'Environmental Measures ' },
  ];

  const exitAnim = (node) => {
    gsap.fromTo(
      node,
      {
        y: 0,
        opacity: 1,
      },
      {
        duration: 0.5,
        y: -10,
        opacity: 0,
        delay: 1.4,
      },
    );
  };

  const enter = (node) => {
    gsap.fromTo(
      node,
      {
        y: 10,
        opacity: 0,
      },
      {
        duration: 0.5,
        y: 0,
        opacity: 1,
      },
    );
  };

  const stopCarousel = () => {
    return clearInterval();
  };

  const h = window.innerHeight;

  const handleScroll = () => {
    window.scrollTo({
      top: h - 100,
      left: 100,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeTextInd >= 5) {
        stopCarousel();
        setActiveTextInd(0);
      } else {
        setActiveTextInd(activeTextInd + 1);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [activeTextInd]);

  return (
    <section className='relative md:h-[110vh] h-screen overflow-hidden md:min-h-[600px] min-h-[500px]'>
      <div className='absolute z-30 container top-0 bottom-0 left-0 right-0 md:h-[100vh] grid md:place-content-center place-content-evenly'>
        <div className='2xl:pb-72 md:pb-64 pb-56 '>
          {/* <div className=' md:pb-56 pb-40 2xl:pb-64'> */}
          <h3 className='text-black font-bold text-center leading-tight animate-fade-up'>
            Reimagining
          </h3>
          <div className='overflow-hidden pt-2'>
            <SwitchTransition>
              <Transition
                appear={true}
                timeout={1400}
                key={activeTextInd}
                onExit={exitAnim}
                onEnter={enter}
                mountOnEnter
                unmountOnExit
              >
                <h2 className='text-greenDark font-bold text-center leading-normal animate-fade-up 2xl:mt-2'>
                  {list[activeTextInd].text}
                </h2>
              </Transition>
            </SwitchTransition>
          </div>
        </div>

        <div className=' grid place-content-center gap-3 animate-fade-up w-fit mx-auto xl:-mt-16'>
          <button
            aria-label='Explore more'
            className='border-2 group border-white rounded-full w-14 h-14 hover:bg-white outline-green outline-offset-4 transition-all mx-auto grid place-content-center'
            onClick={() => handleScroll()}
          >
            <svg
              className=' group-hover:stroke-greenDark stroke-white cursor-pointer transition-all'
              width='46'
              height='46'
              viewBox='0 0 46 46'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11 19C14.6923 19.6471 22.3231 22.7529 23.3077 30C23.5128 26.7647 26.1385 20.0353 35 19'
                strokeWidth='3'
              />
            </svg>
          </button>
          <p className='text-white font-plainNormal tracking-wide  text-center'>Explore</p>
        </div>
      </div>

      <div className='absolute top-0 left-0 right-0 w-full overflow-hidden md:h-[130vh] h-screen md:min-h-[600px] min-h-[500px] z-20'>
        <video
          className='h-[120%] w-full object-cover'
          poster={poster}
          src={vdo}
          loop
          autoPlay
          muted
          playsInline
        />
      </div>
      <div className='banner-image-gradient absolute top-0 left-0 z-20 w-full h-full'>
        <img className='w-full h-full' src={lineOverlay} alt='Background overlay' aria-hidden />
      </div>
    </section>
  );
}
