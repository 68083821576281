import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  HomeScreen,
  AboutScreen,
  Error404Screen,
  ContactScreen,
  Agriculture,
  Resources,
  Technology,
} from '../screens';
import { Header, Footer } from '../modules/core';
import { UIContextProvider } from '../context/uiContext';
import { ToastContainer } from 'react-toastify';

const ViewWithNavAndFooter = () => {
  return (
    <>
      <UIContextProvider>
        <Header />
        <ToastContainer />
        <Outlet />
        <Footer />
      </UIContextProvider>
    </>
  );
};

const routes = () => {
  return [
    {
      path: '/',
      element: <ViewWithNavAndFooter />,
      children: [
        { path: '', element: <HomeScreen /> },
        {
          path: '/technology',
          element: <Technology />,
        },
        {
          path: '/contact-us',
          element: <ContactScreen />,
        },
        { path: '/about-us', element: <AboutScreen /> },
        {
          path: '/agriculture',
          element: <Agriculture />,
        },
        {
          path: '/resources',
          element: <Resources />,
        },
        // { path: "career", element: <CareerScreen /> },
        {
          path: '*',
          element: <Error404Screen />,
        },
      ],
    },
  ];
};

export default routes;
