import { About } from './about';
import { Collaboration } from './collaboration';
import { GreenChoice } from './greenChoice';
import { IntroducePolymer } from './introducePolymer';
import { Possibilities } from './possibilities';
import { SPAComparizon } from './spaComparizon';
import { IcePack } from './icePackProject';
import GetinTouch from '../../modules/core/get-in-touch';
import Banner from '../../modules/core/banner';
import bannerLogo from '../../assets/technology/banner_logo.svg';
import { useEffect } from 'react';

const Technology = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Banner
        title='Grow Sustainably'
        pageTitle='technology'
        subtitle='Harness the power of organic hydrogel for your business and the Earth.'
        logotext={bannerLogo}
        className='tech-banner-wrapper'
      />
      <About />
      <IntroducePolymer />
      <GreenChoice />
      <SPAComparizon />
      <Possibilities />
      <IcePack />
      <Collaboration />

      <GetinTouch bg='#F1EDE6' />
    </>
  );
};

export default Technology;
