import React from 'react';
import bg from '../../../assets/globals/bg-circular-dashed.svg';
import fasalAmrit from '../../../assets/homepage/fasal-amrit-product/polymer_image.webp';
import './style.css';

export default function ProductsView() {
  return (
    <div className='animate-box-fade-in'>
      <div className='my-8 lg:my-12'>
        <div className='grid place-content-center'>
          <div className='p-5 flex items-center overflow-hidden justify-center bg-contain relative'>
            <div
              className='absolute animate-spin-slower inset-0 w-full h-full bg-contain'
              style={{
                background: `url(${bg})`,
                backgroundSize: '100%',
              }}
            />
            <div className='rounded-full 2xl:h-[24rem] 2xl:w-[24rem] lg:h-[25rem] lg:w-[25rem] h-[16rem] w-[16rem] bg-greenDark overflow-hidden'>
              <img
                className='w-full h-full ld:p-10 p-2 object-contain'
                src={fasalAmrit}
                alt='Polymers - The amrit for plants'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
