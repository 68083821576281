import Image1 from '../resources/pressReleases/image-01.webp';
import Image2 from '../resources/pressReleases/image-02.webp';
import Image3 from '../resources/pressReleases/image-03.webp';
import Image4 from '../resources/pressReleases/image-04.webp';
import Image5 from '../resources/pressReleases/image-05.webp';
import Image6 from '../resources/pressReleases/image-06.webp';
import Image7 from '../resources/pressReleases/image-07.webp';
import Image8 from '../resources/pressReleases/image-08.webp';
import Image9 from '../resources/pressReleases/image-09.webp';

export const pressReleases = [
  { imgSrc: Image1, id: 1 },
  { imgSrc: Image2, id: 2 },
  { imgSrc: Image3, id: 3 },
  { imgSrc: Image4, id: 4 },
  { imgSrc: Image5, id: 5 },
  { imgSrc: Image6, id: 6 },
  { imgSrc: Image7, id: 7 },
  { imgSrc: Image8, id: 8 },
  { imgSrc: Image9, id: 9 },
];
