import ContentTile from '../../modules/atoms/contentTile';
import Promoting from '../../assets/about/guiding-values/promoting.svg';
import Empowering from '../../assets/about/guiding-values/empowerring.svg';
import EnvFriendly from '../../assets/about/guiding-values/env-friendly.svg';
import NaturalResources from '../../assets/about/guiding-values/naturalresources.svg';
import Diamond from '../../assets/about/guiding-values/centerdiamonds.svg';
import './index.css';

const Card = ({ heading, para, icon, className }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center max-w-[280px] lg:max-w-[350px] xl:max-w-sm ${className}`}
    >
      <span className='w-[70px] h-[70px] 2xl:w-auto 2xl:h-auto'>
        <img src={icon} aria-hidden alt={heading} />
      </span>
      <h5 className='font-bold text-[18px] 2xl:text-[20px] mt-2 lg:mt-1 2xl:mt-4 '>{heading}</h5>
      <p className='font-normal opacity-60 text-center mt-2 text-base max-w-[350px]'>{para}</p>
    </div>
  );
};

const VerticalDivider = () => {
  return (
    <div className='gradient-vertical-divider hidden md:block w-[1px] h-[160px] 2xl:h-[260px]' />
  );
};

const HorizontalDiamondDivider = ({ className }) => {
  return (
    <div
      className={`flex gap-x-0 md:gap-x-6 items-center justify-center md:justify-center ${className}`}
    >
      <div className='gradient-horizontal-divider w-[30%] md:w-[38%] xl:w-[35%] 2xl:w-[30%] h-[1px]' />
      <span className='w-[20%] md:w-fit flex justify-center'>
        <img className='h-5 md:h-auto' src={Diamond} alt='diamond' aria-hidden />
      </span>
      <div className='gradient-horizontal-divider w-[30%] md:w-[38%] xl:w-[35%] 2xl:w-[30%] h-[1px]' />
    </div>
  );
};

export const GuidingValues = () => {
  return (
    <div className='blade-pt-sm blade-pb bg-brownLight'>
      <div className='container'>
        <ContentTile
          title='Our guiding values'
          center
          className='mx-auto max-w-[500px]'
          p2='EF Polymer thrives on empowering growers and rejuvenating nature!'
        />
        <div className='flex flex-col md:flex-row mt-8 lg:mt-4 2xl:mt-16 items-center justify-center gap-y-10 md:gap-x-10 2xl:gap-x-14'>
          <Card
            heading='Environment-friendly'
            para='All the initiatives taken by EF Polymer are designed to help the environment and bio-degrade effectively.'
            icon={EnvFriendly}
          />
          <VerticalDivider />
          <HorizontalDiamondDivider className='w-full md:hidden' />
          <Card
            heading='Saving natural resources'
            para='Water, soil, and air are the three most vital natural resources getting polluted or depleted rapidly. EF Polymer strives to save and restore these resources naturally. '
            icon={NaturalResources}
            className='md:pt-6'
          />
        </div>

        <HorizontalDiamondDivider className='my-10 md:my-4 lg:my-0 2xl:my-6' />

        <div className='flex flex-col md:flex-row items-center justify-center gap-y-10 md:gap-x-10 lg:gap-x-14'>
          <Card
            heading='Promoting sustainability'
            para='With every endeavor, EF Polymer encourages farmers and growers to use sustainable practices of agriculture.'
            icon={Promoting}
          />
          <VerticalDivider />
          <HorizontalDiamondDivider className='w-full md:hidden' />
          <Card
            heading='Empowering society'
            para='Our team strives to educate, and boost the use of organic and sustainable means to grow their profits and production.'
            icon={Empowering}
          />
        </div>
      </div>
    </div>
  );
};
