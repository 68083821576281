import { ContentTile } from '../../modules/atoms';
import SoilHealth from '../../assets/technology/about/soilhealth.webp';
import BioWaste from '../../assets/technology/about/Bio-waste.webp';
import Organic from '../../assets/technology/about/Organic.webp';
import WaterConservation from '../../assets/technology/about/waterconservation.webp';
import { Swiper, SwiperSlide } from 'swiper/react';

const imageData = [
  { id: 1, img: WaterConservation, name: 'Water Conservation' },
  { id: 2, img: BioWaste, name: 'Bio-waste Management' },
  { id: 3, img: SoilHealth, name: 'Soil Health' },
  { id: 4, img: Organic, name: 'Organic Farming' },
];

export const About = () => {
  return (
    <div className='blade-pt blade-pb bg-brownLight'>
      <div className='container'>
        <ContentTile
          label='About the Polymer'
          className='max-w-xl mx-auto px-4 sm:px-0'
          center
          title='Thriving Ecosystem, Rising Community!'
          p2='Now more than ever, the world needs a sustainable and viable solution to save natural resources! EF Polymer; the superabsorbent hydrogel, can be a powerful source to help regenerate land and create better opportunities for society and business.'
        />
        <div className='hidden lg:flex items-center mt-24 gap-x-20'>
          {imageData.map((image) => (
            <div
              key={image.id}
              className={`relative ${
                image.id === 1 || image.id === 4 ? '-translate-y-[70%]' : 'translate-y-0'
              }`}
            >
              <img src={image.img} alt={image.name} />
              <h5 className='absolute text-center bottom-4 left-0 mx-auto text-white right-0'>
                {image.name}
              </h5>
            </div>
          ))}
        </div>

        {/* Mobile View */}
        <div className='mt-8 md:mt-16 lg:hidden'>
          <Swiper className='' spaceBetween={30} loop={true} autoplay slidesPerView={1}>
            <SwiperSlide>
              <div className='flex justify-center items-center gap-x-6'>
                {imageData.map((image) => {
                  if (image.id % 2 !== 0) {
                    return (
                      <div key={image.id} className='relative'>
                        <img src={image.img} alt={image.name} />
                        <h5 className='absolute text-base sm:text-lg text-center bottom-4 left-0 mx-auto text-white right-0'>
                          {image.name}
                        </h5>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='flex justify-center items-center gap-x-6'>
                {imageData.map((image) => {
                  if (image.id % 2 === 0) {
                    return (
                      <div key={image.id} className='relative'>
                        <img src={image.img} alt={image.name} />
                        <h5 className='absolute text-base sm:text-lg text-center bottom-4 left-0 mx-auto text-white right-0'>
                          {image.name}
                        </h5>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};
