import React from 'react';
import { ContentTile } from '../../../modules/atoms';
import Cards from './cards';
import ProductsView from './products-view';
import Logo1 from '../../../assets/homepage/product/ecocert_logo.svg';
import Logo2 from '../../../assets/homepage/product/omj_logo.svg';

const Products = () => {
  return (
    <section className='container blade-pt blade-pb'>
      <ContentTile
        label='THE PRODUCT'
        className='max-w-xl mx-auto'
        center
        title='Amrit for plants'
        p2='Farmers and growers can rebuild the earth quicker than anyone else. 
        Contributing to their efforts, EF Polymer created a solution that would promote sustainable agriculture, save natural resources, 
        and help farmers grow a better yield.'
        p4='What would you call such a miraculous innovation? <br class="md:block hidden "/> We call it Fasal Amrit; the Amrit for Plants!'
      />

      <ProductsView />
      <div className='flex items-center gap-x-8 justify-center'>
        <img src={Logo1} alt='Ecocert logo' />
        <img src={Logo2} alt='OMJ logo' />
      </div>
      <h3 className='font-plainSemibold mb-8 lg:mb-12 2xl:mt-24 md:mt-10 xl:text-[32px] 2xl:text-[36px] text-[28px] leading-tight text-center text-greenDark'>
        Why use Fasal Amrit?
      </h3>
      <Cards />
    </section>
  );
};

export default Products;
