import ContentTile from '../../modules/atoms/contentTile';
import amrit from '../../assets/agriculture/eco-friendly-hydrogel/orange-peels.webp';

// import Amazon from "../../assets/agriculture/eco-friendly-hydrogel/amazon_logo.png";
// import BigHaat from "../../assets/agriculture/eco-friendly-hydrogel/bighaat_logo.png";
// import Flipkart from "../../assets/agriculture/eco-friendly-hydrogel/flipkart_logo.png";

import { SectionLink } from '../../modules/atoms';

import dashedCircle from '../../assets/globals/bg-circular-dashed.svg';
import './index.css';

export const EcoFriendlyHydrogel = () => {
  return (
    <div className='flex flex-col lg:flex-row '>
      <div className='h-auto bg-brownMedium blade-pt md:blade-pb-lg pb-4 px-4 sm:px-12 lg:px-0 w-full lg:w-[50%] xl:w-[45%] 2xl:w-[55%] flex items-center justify-center'>
        <div className='relative flex p-6 md:p-8 lg:p-10 justify-center items-center overflow-hidden'>
          <div
            className='absolute animate-spin-slower inset-0 w-full h-full bg-contain'
            style={{
              background: `url(${dashedCircle})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
            }}
          />
          <div className='rounded-full 2xl:h-[32rem] 2xl:w-[32rem] xl:h-[25rem] xl:w-[25rem] md:h-[20rem] md:w-[20rem] w-[16rem] h-[16rem] bg-greenDark overflow-hidden'>
            <img
              alt='Aco friendly hydrogel made from fruit peels'
              src={amrit}
              className='w-full h-full object-contain p-2 lg:p-10'
            />
          </div>
        </div>
      </div>

      <div className='eco-friendly-right-container  w-full lg:w-[50%] xl:w-[55%] 2xl:w-[45%] lg:self-stretch pt-4 md:pt-6 lg:pt-20 blade-pb-sm px-4 sm:px-12 xl:px-20'>
        <ContentTile
          title='Eco-friendly Hydrogel'
          themeDark={window.innerWidth < 1024 ? false : true}
          className='md:mx-0 mx-auto max-w-md md:max-w-2xl'
          center={window.innerWidth < 1024 ? true : false}
          p2='A sustainable solution to grow a healthy plant, profitable yield, maintain water retention and benefits the environment simultaneously. Created using organic waste, EF Polymer can be your one-stop solution to reduce the use of chemicals and water, boost plant growth naturally, and increase profits.'
        />
        <div className='flex mt-6 md:mt-10 items-center justify-center lg:justify-start gap-x-6 md:gap-x-12'>
          <div>
            <h4 className='lg:text-white text-center lg:text-left font-plainSemibold text-2xl sm:text-[26px] md:text-3xl xl:text-4xl'>
              40%
            </h4>
            <p className='lg:text-white font-plainThin text-[14px] sm:text-base md:text-lg mt-1'>
              reduce of water{' '}
            </p>
          </div>
          <div className='w-[2px] h-[70px] bg-greenDark bg-opacity-75 lg:bg-[#71B2454D]' />
          <div>
            <h4 className='lg:text-white text-center lg:text-left font-plainSemibold text-2xl sm:text-[26px] md:text-3xl xl:text-4xl'>
              20%
            </h4>
            <p className='lg:text-white font-plainThin text-[14px] sm:text-base md:text-lg mt-1'>
              Save fertilizer
            </p>
          </div>
        </div>

        <div className='mt-8 animate-box-fade-in'>
          <p className='text-greenDark md:text-left text-center md:text-yellow font-plainSemibold text-lg md:text-xl'>
            Get your pack now!
          </p>
          <SectionLink
            title='Contact Us'
            className='px-6 pt-2 pb-[10px] mt-4 font-bold md:block hidden'
            sectionId='get-in-touch'
            darkTheme
          />
          <SectionLink
            title='Contact Us'
            className='px-6 pt-2 pb-[10px] mt-4 font-bold md:hidden block mx-auto'
            sectionId='get-in-touch'
          />
        </div>
        {/* <div className="grid grid-cols-3 sm:grid-cols-[128px_128px_128px] xl:grid-cols-[160px_160px_160px] mt-4 sm:mt-8 sm:items-center gap-y-4 gap-x-2 sm:gap-x-6">
          <a
            href="https://www.amazon.in/Biodegradable-Fasal-Amrit-Hydrogel-Agriculture/dp/B09NDB6S4K/[%E2%80%A6]J4gQAXCh1xtbhi-pwBB-8C1Bk-WlfBEWdAsmJJL9bvHhoCJ2UQAvD_BwE&th=1"
            target="_blank"
          >
            <img
              className="w-[100px] sm:w-40 xl:w-40"
              src={Amazon}
              alt="amazon logo"
            />
          </a>
          <a
            href="https://www.bighaat.com/products/fasal-amrit?pf=search"
            target="_blank"
          >
            <img
              className="w-[100px] sm:w-40 xl:w-40"
              src={BigHaat}
              alt="bighaat logo"
            />
          </a>
          <a
            href="https://www.flipkart.com/fasal-amrit-100-organic-biodegradable-hydrogel-home-plant-gardening-potting-mixture/p/itma9e6a70bd41a1?pid=SMNGTHJ6JQGU84XP&lid=LSTSMNGTHJ6JQGU84XP0GOUGX&marketplace=FLIPKART&q=ef+polymer+fasal+amrit&store=search.flipkart.com&srno=s_1_2&otracker=search&otracker1=search&fm=Search&iid=3f2fbbe4-dac8-4e2f-a9e9-a724b7a64078.SMNGTHJ6JQGU84XP.SEARCH&ppt=sp&ppn=sp&ssid=dxaw8xig5c0000001700733454399&qH=ba728ec6cc3169aa"
            target="_blank"
          >
            <img
              className="w-[100px] sm:w-40 xl:w-40"
              src={Flipkart}
              alt="flipkart logo"
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};
