import ContentTitle from '../../modules/atoms/contentTile';
import SeedDrill from '../../assets/agriculture/steps/seedDrill.webp';
import Drone from '../../assets/agriculture/steps/drone.webp';
import Gardening from '../../assets/agriculture/steps/gardening.webp';
import Broadcasting from '../../assets/agriculture/steps/broadcasting.webp';

const data = [
  {
    id: 1,
    heading: 'Seed-Drill Method',
    des: 'Mix EF Polymer with the seeds using a sheet and then pour it all into the seed-drill machine. Now, sow the seeds as you would regularly do. Water the field regularly for the first few weeks, and then let the plants grow naturally with minimal irrigation. ',
    img: SeedDrill,
  },
  {
    id: 2,
    heading: 'Broadcasting Method',
    des: 'Mix EF Polymer with the seeds using a sheet. Then spread the seeds as you would normally do. Water the field more than usual, only for the first phase, then witness the difference in crop growth!',
    img: Broadcasting,
  },
  {
    id: 3,
    heading: 'Gardening Method',
    des: 'Mix EF Polymer with the soil right before planting the sapling. Once done, carefully place the sapling and water the plant. The budding plant will turn into a full-grown bloom in no-time. ',
    img: Gardening,
  },
  {
    id: 4,
    heading: 'Drone Method',
    des: 'Use EF Polymer on its own or blend it with your fertilizer when spraying it using a drone.',
    img: Drone,
  },
];

const Card = ({ heading, img, des }) => {
  return (
    <div className='self-stretch'>
      <div className='border-2 w-full h-auto md:h-[250px] lg:h-[260px] 2xl:h-[280px] rounded-xl border-grayLight flex items-center justify-center py-4'>
        <img
          className='w-[240px] h-[200px] md:h-auto md:w-[75%] lg:w-[75%] xl:w-[82%] object-contain'
          src={img}
          alt={heading}
        />
      </div>
      <p className='font-plainSemibold text-center md:text-left mt-6 text-greenDark text-xl'>
        {heading}
      </p>
      <p className='font-normal opacity-60 text-center md:text-left mt-2 text-sm md:text-base xl:max-w-[280px] 2xl:max-w-[96%]'>
        {des}
      </p>
    </div>
  );
};

export const Steps = () => {
  return (
    <div className='blade-pt blade-pb px-4 bg-brownMedium '>
      <ContentTitle
        title='Start growing your way!'
        label='Ways to use'
        center
        className='mx-auto max-w-2xl'
        p2='Incorporate EF Polymer into your growing techniques seamlessly.'
      />
      <div className=' grid md:grid-cols-[300px_300px] lg:grid-cols-[360px_360px] xl:grid-cols-[275px_275px_275px_275px] 2xl:grid-cols-[300px_300px_300px_300px] items-center justify-center 2xl:gap-12 gap-6 mt-8 sm:mt-12 '>
        {data.map((item, index) => (
          <Card key={index} heading={item.heading} des={item.des} img={item.img} />
        ))}
      </div>
    </div>
  );
};
