import { useEffect } from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import routes from "./routes";
import emailjs from "@emailjs/browser";

const RenderRoutes = () => {
  let routeTree = useRoutes(routes());
  return routeTree;
};

function App() {
  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
  }, []);

  return (
    <>
      <BrowserRouter>
        <RenderRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
