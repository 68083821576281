import React, { useState } from 'react';
import './style.css';
import { Formik } from 'formik';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import spinner from '../../../assets/spinner.svg';

const regions = [
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    label: 'Albania',
    value: 'Albania',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
  },
  {
    label: 'Andorra',
    value: 'Andorra',
  },
  {
    label: 'Angola',
    value: 'Angola',
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
  },
  {
    label: 'Antarctica',
    value: 'Antarctica',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
  },
  {
    label: 'Aruba',
    value: 'Aruba',
  },
  {
    label: 'Australia',
    value: 'Australia',
  },
  {
    label: 'Austria',
    value: 'Austria',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
  },
  {
    label: 'Belize',
    value: 'Belize',
  },
  {
    label: 'Benin',
    value: 'Benin',
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
  },
  {
    label: 'Bolivia',
    value: 'Bolivia',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
  },
  {
    label: 'Bouvet Island',
    value: 'Bouvet Island',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
  },
  {
    label: 'Brunei',
    value: 'Brunei',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
  },
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'Cape Verde',
    value: 'Cape Verde',
  },
  {
    label: 'Cayman Islands',
    value: 'Cayman Islands',
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
  },
  {
    label: 'Chad',
    value: 'Chad',
  },
  {
    label: 'Chile',
    value: 'Chile',
  },
  {
    label: 'China',
    value: 'China',
  },
  {
    label: 'Christmas Island',
    value: 'Christmas Island',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
  },
  {
    label: 'Comoros',
    value: 'Comoros',
  },
  {
    label: 'Congo',
    value: 'Congo',
  },
  {
    label: 'Congo, The Democratic Republic of the',
    value: 'Congo, The Democratic Republic of the',
  },
  {
    label: 'Cook Islands',
    value: 'Cook Islands',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    label: "Cote d'Ivoire",
    value: "Cote d'Ivoire",
  },
  {
    label: 'Croatia',
    value: 'Croatia',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic',
  },
  {
    label: 'Denmark',
    value: 'Denmark',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
  },
  {
    label: 'Dominica',
    value: 'Dominica',
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
  },
  {
    label: 'East Timor',
    value: 'East Timor',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
  },
  {
    label: 'Falkland Islands',
    value: 'Falkland Islands',
  },
  {
    label: 'Faroe Islands',
    value: 'Faroe Islands',
  },
  {
    label: 'Fiji Islands',
    value: 'Fiji Islands',
  },
  {
    label: 'Finland',
    value: 'Finland',
  },
  {
    label: 'France',
    value: 'France',
  },
  {
    label: 'French Guiana',
    value: 'French Guiana',
  },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
  },
  {
    label: 'French Southern territories',
    value: 'French Southern territories',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
  },
  {
    label: 'Gambia',
    value: 'Gambia',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Germany',
    value: 'Germany',
  },
  {
    label: 'Ghana',
    value: 'Ghana',
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
  },
  {
    label: 'Greece',
    value: 'Greece',
  },
  {
    label: 'Greenland',
    value: 'Greenland',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
  },
  {
    label: 'Guadeloupe',
    value: 'Guadeloupe',
  },
  {
    label: 'Guam',
    value: 'Guam',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands',
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
  },
  {
    label: 'India',
    value: 'India',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
  },
  {
    label: 'Iran',
    value: 'Iran',
  },
  {
    label: 'Iraq',
    value: 'Iraq',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
  },
  {
    label: 'Israel',
    value: 'Israel',
  },
  {
    label: 'Italy',
    value: 'Italy',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
  },
  {
    label: 'Japan',
    value: 'Japan',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
  },
  {
    label: 'Kazakstan',
    value: 'Kazakstan',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    label: 'Laos',
    value: 'Laos',
  },
  {
    label: 'Latvia',
    value: 'Latvia',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
  },
  {
    label: 'Libyan Arab Jamahiriya',
    value: 'Libyan Arab Jamahiriya',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    label: 'Macao',
    value: 'Macao',
  },
  {
    label: 'Macedonia',
    value: 'Macedonia',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
  },
  {
    label: 'Mali',
    value: 'Mali',
  },
  {
    label: 'Malta',
    value: 'Malta',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    label: 'Martinique',
    value: 'Martinique',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
  },
  {
    label: 'Micronesia, Federated States of',
    value: 'Micronesia, Federated States of',
  },
  {
    label: 'Moldova',
    value: 'Moldova',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
  },
  {
    label: 'Netherlands Antilles',
    value: 'Netherlands Antilles',
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    label: 'Niger',
    value: 'Niger',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
  },
  {
    label: 'Niue',
    value: 'Niue',
  },
  {
    label: 'Norfolk Island',
    value: 'Norfolk Island',
  },
  {
    label: 'North Korea',
    value: 'North Korea',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  {
    label: 'Norway',
    value: 'Norway',
  },
  {
    label: 'Oman',
    value: 'Oman',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
  },
  {
    label: 'Palau',
    value: 'Palau',
  },
  {
    label: 'Palestine',
    value: 'Palestine',
  },
  {
    label: 'Panama',
    value: 'Panama',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
  },
  {
    label: 'Peru',
    value: 'Peru',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
  },
  {
    label: 'Poland',
    value: 'Poland',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
  },
  {
    label: 'Reunion',
    value: 'Reunion',
  },
  {
    label: 'Romania',
    value: 'Romania',
  },
  {
    label: 'Russian Federation',
    value: 'Russian Federation',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
  },
  {
    label: 'Saint Helena',
    value: 'Saint Helena',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Samoa',
    value: 'Samoa',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  {
    label: 'South Korea',
    value: 'South Korea',
  },
  {
    label: 'Spain',
    value: 'Spain',
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    label: 'Sudan',
    value: 'Sudan',
  },
  {
    label: 'Suriname',
    value: 'Suriname',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
  },
  {
    label: 'Swaziland',
    value: 'Swaziland',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
  },
  {
    label: 'Syria',
    value: 'Syria',
  },
  {
    label: 'Taiwan',
    value: 'Taiwan',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    label: 'Tanzania',
    value: 'Tanzania',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
  },
  {
    label: 'Togo',
    value: 'Togo',
  },
  {
    label: 'Tokelau',
    value: 'Tokelau',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom',
  },
  {
    label: 'United States',
    value: 'United States',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
  },
  {
    label: 'Venezuela',
    value: 'Venezuela',
  },
  {
    label: 'Vietnam',
    value: 'Vietnam',
  },
  {
    label: 'Virgin Islands,British',
    value: 'Virgin Islands,British',
  },
  {
    label: 'Virgin Islands,U.S.',
    value: 'Virgin Islands,U.S.',
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
  },
  {
    label: 'Western Sahara',
    value: 'Western Sahara',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
  },
  {
    label: 'Yugoslavia',
    value: 'Yugoslavia',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
  },
];

export default function GetinTouch({ bg, head, subhead }) {
  const [isLoading, setLoading] = useState(false);
  const [selectedRegion, setRegion] = useState('');
  const [showDropdown, setDropdown] = useState(false);
  const initialValues = {
    email: '',
    phoneNumber: '',
    fullName: '',
    message: '',
    region: '',
  };

  const submitHandler = async (values, { resetForm }) => {
    setLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        { ...values, region: selectedRegion },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      )
      .then((result) => {
        toast.success('Form Submitted Successfully', {
          position: 'top-right',
          autoClose: 4000,
          closeOnClick: true,
        });
      })
      .catch((error) => {
        toast.warn('Something went wrong, Please try later!', {
          position: 'top-right',
          autoClose: 4000,
          closeOnClick: true,
        });
      })
      .finally(() => {
        resetForm();
        setRegion('');
        setLoading(false);
      });
  };

  const validator = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Please Enter Your Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid Email Address';
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = 'Please Enter Your Phone Number';
    } else if (
      String(values.phoneNumber).toString().length < 10 ||
      String(values.phoneNumber).toString().length > 14
    ) {
      errors.phoneNumber = 'Please Enter Valid Phone Number With Country Code';
    }
    if (!values.fullName) {
      errors.fullName = 'Please Enter Your Full Name';
    }
    if (!selectedRegion) {
      errors.region = 'Please Select Any Region';
    }
    return errors;
  };

  return (
    <section
      className={`-mb-[2px] blade-pt-sm lg:pt-56`}
      style={{
        background: `linear-gradient(to bottom, ${bg ? bg : '#F1EDE6'} 50%, #006633 0%)`,
      }}
      id='get-in-touch'
    >
      <ToastContainer />
      <div className='inside-wrapper container relative lg:flex lg:p-20 sm:p-12 px-4 py-8'>
        <div className='flex-1'>
          <h2 className='text-white leading-tight font-plainNormal max-w-md'>
            {head ? head : 'We’re here to help!'}
          </h2>
          <h3 className='text-yellow font-plainSemibold mt-2 lg:mt-3 xl:mt-5'>
            {subhead ? subhead : 'Connect with us.'}
          </h3>

          <p className='h-[0.17rem] w-[15%] bg-yellow mt-2 xl:mt-3'></p>
          {/* <a
            className="font-normal text-white mt-4 block"
            href="mailto:efp-japan-office@efpolymer.com"
          >
            <span className="text-white">Email:</span>{" "}
            efp-japan-office@efpolymer.com
          </a> */}
        </div>
        <div className='flex-1'>
          <Formik
            initialValues={initialValues}
            validate={(values) => validator(values)}
            onSubmit={(values, actions) => submitHandler(values, actions)}
            validateOnChange={false}
          >
            {({ values, errors, handleSubmit, handleChange }) => (
              <form
                onSubmit={handleSubmit}
                className='bg-white md:p-10 p-5 grid gap-4 rounded-3xl lg:-mt-56 mt-10 shadow-2xl max-w-xl'
              >
                <div className=''>
                  <label htmlFor='name' className='text-greenDark'>
                    Full Name *
                  </label>
                  <input
                    id='name'
                    name='fullName'
                    placeholder='Enter Name'
                    className={`${
                      errors.fullName ? 'border-red-800' : 'border-grayLight focus:border-greenDark'
                    }  placeholder:font-plainLight`}
                    value={values.fullName}
                    onChange={handleChange}
                  />
                  {errors.fullName && (
                    <small className='text-red-800  text-opacity-80 dark:text-danger font-semibold flex flex-nowrap items-center gap-1'>
                      {errors.fullName}
                    </small>
                  )}
                </div>
                <div>
                  <label className='text-greenDark'>E-mail *</label>
                  <input
                    className={`${
                      errors.email ? 'border-red-800' : 'border-grayLight focus:border-greenDark'
                    } 
                    placeholder:font-plainLight`}
                    placeholder='Enter Email'
                    value={values.email}
                    id='email'
                    name='email'
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <small className='text-red-800  text-opacity-80 dark:text-danger font-semibold flex flex-nowrap items-center gap-1'>
                      {errors.email}
                    </small>
                  )}
                </div>
                <div>
                  <label className='text-greenDark'>Phone *</label>
                  <input
                    className={`${
                      errors.phoneNumber
                        ? 'border-red-800'
                        : 'border-grayLight focus:border-greenDark'
                    } 
                    placeholder:font-plainLight`}
                    placeholder='Enter Phone'
                    value={values.phoneNumber}
                    name='phoneNumber'
                    id='phoneNumber'
                    type='number'
                    onChange={handleChange}
                  />
                  {errors.phoneNumber && (
                    <small className='text-red-800  text-opacity-80 dark:text-danger font-semibold flex flex-nowrap items-center gap-1'>
                      {errors.phoneNumber}
                    </small>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='region'
                    className='text-greenDark flex items-center justify-between'
                  >
                    Region *
                  </label>
                  <span
                    onClick={() => setDropdown(!showDropdown)}
                    className={`w-full flex justify-between mt-1 ${
                      showDropdown ? 'border-greenDark' : 'border-grayLight'
                    } ${
                      selectedRegion ? 'text-black' : 'text-[#9ca3af]'
                    } relative cursor-text border-b outline-none h-8 flex items-end font-plainLight pb-[3px]`}
                  >
                    {selectedRegion !== '' ? selectedRegion : 'Select any region'}
                    <div
                      className={`w-full overflow-auto absolute top-full z-50 bg-white shadow-xl h-[200px] ${
                        showDropdown ? 'block' : 'hidden'
                      }`}
                    >
                      <ul className='leading-10 py-2'>
                        {regions.map((region, idx) => (
                          <li
                            key={idx}
                            className={`${
                              selectedRegion === region.value
                                ? 'bg-green text-white hover:text-white'
                                : 'bg-white text-black hover:bg-slate-100 '
                            } px-4 cursor-pointer`}
                            onClick={() => setRegion(region.value)}
                          >
                            {region.label}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Dropdown Arrow */}
                    <span
                      onClick={() => setDropdown(!showDropdown)}
                      className={`${
                        showDropdown ? 'rotate-180' : 'rotate-0'
                      } transition-all cursor-pointer duration-300 ease-in-out`}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={2}
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                        />
                      </svg>
                    </span>
                  </span>
                  {errors.region && (
                    <small className='text-red-800  text-opacity-80 dark:text-danger font-semibold flex flex-nowrap items-center gap-1'>
                      {errors.region}
                    </small>
                  )}
                </div>

                <div>
                  <label className='text-greenDark'>Message</label>
                  <textarea
                    type='message'
                    rows={3}
                    value={values.message}
                    name='message'
                    id='message'
                    onChange={handleChange}
                    placeholder='Enter Message'
                    className='border px-2 placeholder:font-plainLight focus:border-greenDark border-grayLight rounded mt-2'
                  />
                </div>
                <button
                  aria-label='Submit form'
                  className='bg-green outline-offset-4 outline-green hover:bg-greenDark relative active:bg-greenDark 
                              rounded-full px-10 py-3 transition-all duration-300 ease-linear text-greenLight w-fit'
                  // disabled={isLoading}
                  type='submit'
                >
                  {isLoading ? (
                    <div className='flex relative'>
                      <span className='text-white opacity-0'>Submit</span>
                      <img
                        className='h-10 absolute inset-0 m-auto'
                        src={spinner}
                        aria-hidden
                        alt='Form submit icon'
                      />
                    </div>
                  ) : (
                    'Submit'
                  )}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
}
